import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Button, Checkbox, Grid, Header, Icon, Message, Modal, Segment } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import IModelCapacity from "../../model/IModelCapacity";
import IModelCapacityCompany from "../../model/IModelCapacityCompany";
import IModelInOutCapacity from "../../model/IModelInOutCapacity";
import IModelService from "../../model/IModelService";
import IApplicationProps from "../application-collection/IApplicationProps";
import Emsp from "../ui-element/Emsp";
import CapacityCompanyEdit from "./CapacityCompanyEdit";
import CapacityEdit from "./CapacityEdit";
import EncodeYW from "./EncodeYW";
import Legend from "./Legend";
import ModelsStacker from "./ModelsStacker";
import "./Style.scss";
import Week from "./Week";




interface IServiceFilter
{
	active: boolean;
	service: IModelService;
}




interface IState
{
	selectionStartYW: number;
	selectionEndYW: number;
	isSelectionRunning: boolean;

	filterOut0: boolean;
	filterOut1: boolean;
	filterIn0: boolean;
	filterIn1: boolean;

	serviceFilters: IServiceFilter[];

	models: IModelInOutCapacity[];

	stackLength: number;

	editionCapacity: Partial<IModelCapacity>;
	editionCapacityCompany: Partial<IModelCapacityCompany>;

	editorKey: number;

	years: number[];

	highlighted: number;
}




interface IDomOwnerResult
{
	className: "Week" | "WeekSpan";
	dom: HTMLElement;
	weekDom?: HTMLElement;
}




export default class CapacityCalendar extends React.Component<IApplicationProps, IState>
{
	state =
	{
		selectionStartYW: -1,
		selectionEndYW: -1,
		isSelectionRunning: false,

		filterOut0: true,
		filterOut1: true,
		filterIn0: true,
		filterIn1: true,
		serviceFilters: [] as IServiceFilter[],

		models: [] as IModelInOutCapacity[],

		stackLength: 0,

		editionCapacity: undefined as Partial<IModelCapacity>,
		editionCapacityCompany: undefined as Partial<IModelCapacityCompany>,

		editorKey: 0,

		years: (() =>
		{
			const output = [moment().year()];

			if (moment().isoWeek() > 24)
				output.push(output[0] + 1); // Next year

			return output;
		})(),

		highlighted: -1,
	};




	fetchers = Fetcher.CreateList();




	componentDidMount()
	{
		this.fetchModels();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
		window.removeEventListener("mouseup", this.windowOnMouseUpBound);
	}




	windowOnMouseUpBound = () =>
	{
		if (this.state.isSelectionRunning)
		{
			this.setState({isSelectionRunning: false});

			if (this.state.editionCapacity === undefined)
			{
				const editionCapacity =
				{
					start_year_week: this.selectionMin,
					end_year_week : this.selectionMax,
					type: 0,
				} as Partial<IModelCapacity>;

				this.setState({editionCapacity});

				window.removeEventListener("mouseup", this.windowOnMouseUpBound);
			}
		}
	}




	onMouseDownBound = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
	{
		if (event.buttons === 1 && User.Instance.company_type === 1)
		{
			const owner = this.domOwner(event.target);

			if (!!owner && owner.className === "Week")
			{
				const current = EncodeYW(moment().year(), moment().isoWeek());
				const yw = parseInt(owner.dom.getAttribute("data-yw"));

				if (current <= yw)
				{
					this.setState(
					{
						selectionStartYW: yw,
						selectionEndYW: yw,
						isSelectionRunning: true,
					});

					window.addEventListener("mouseup", this.windowOnMouseUpBound, {passive: true});
				}
			}
		}
	}




	onMouseOverBound = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
	{
		const owner = this.domOwner(event.target);

		if (owner !== undefined)
		{
			if (this.state.isSelectionRunning)
			{
				const current = EncodeYW(moment().year(), moment().isoWeek());
				const yw = parseInt(owner.dom.getAttribute("data-yw"));

				if (yw >= current)
					this.setState({ selectionEndYW: yw });
			}
			else if (owner.className === "WeekSpan")
			{
				if (owner.dom.getAttribute("data-is_in_filter") === "true")
				{
					const id = parseInt(owner.dom.getAttribute("data-model_id"));
					this.setState({ highlighted: id });
				}
			}
			else
			{
				this.setState({ highlighted: -1 });
			}
		}
	}




	/**
	 * Check if user has clicked on WeekSpan element and open the editor based on direction (in/out).
	 */
	onClickBound = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
	{
		if (event.buttons !== 1)
		{
			const owner = this.domOwner(event.target);

			if (owner !== undefined && owner.className === "WeekSpan")
			{
				const id = parseInt(owner.dom.getAttribute("data-model_id"));
				const model = _.find(this.state.models, (m) => m.id === id);

				if (model !== undefined)
				{
					const isOut = (model.dir === "out");

					this.setState(
					{
						editionCapacity: (
							isOut ?
							model.data as IModelCapacity :
							undefined
						),

						editionCapacityCompany: (
							!isOut ?
							model.data as IModelCapacityCompany :
							undefined
						),
					});
				}
			}
		}
	}




	/**
	 * Extract the WeekSpan or Week DOM element from targeted dom element.
	 *
	 * @param element any Targeted HTML node.
	 * @param findWeekParent if true and the element is a WeekSpan it will also include the Week element.
	 */
	domOwner(element: any, findWeekParent: boolean = false): IDomOwnerResult
	{
		if (!!element) // Element !== undefined
		{
			if (element.classList.contains("Week"))
			{
				return {className: "Week", dom: element};
			}
			else if (element.classList.contains("WeekSpan"))
			{
				const result: IDomOwnerResult = {className: "WeekSpan", dom: element};

				if (findWeekParent)
					result.weekDom = this.domOwner(element.parentElement).dom;

				return result;
			}
			else if (element.parentElement !==  document as any)
			{
				return this.domOwner(element.parentElement);
			}
		}

		return undefined;
	}




	fetchModels(years?: number[])
	{
		if (years === undefined)
		{
			years = this.state.years;
		}

		new Fetcher<IModelInOutCapacity[]>(
		{
			"r": "node/capacity-calendar/in-out-capacities",
			"years": years.join(","),
		})
		.get()
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{

			let editorKey = this.state.editorKey;
			editorKey++;

			const stackLength = ModelsStacker.Run(data).length;

			const serviceFiltersMap = new Map<number, IServiceFilter>();

			for (const model of data)
			{
				const service = model.dir === "out" ?
								(model.data as IModelCapacity).service :
								(model.data as IModelCapacityCompany).capacity.service;

				if (!serviceFiltersMap.has(service.id))
					serviceFiltersMap.set(service.id, {active: true, service});
			}

			const serviceFilters = Array.from(serviceFiltersMap.values());

			this.setState({models: data, stackLength, editorKey, serviceFilters});
		})
		.run();
	}




	renderWeeks(year: number)
	{
		return (
		<div className="weeks" key={year}>

			<Header as="h1">
				{year}
			</Header>

			<div className="content">
			{
				_.times(moment().year(year).isoWeeksInYear(), (index: number) =>
				{
					const current = EncodeYW(moment().year(), moment().isoWeek());
					const yw = EncodeYW(year, index + 1);

					if (yw > current - 5)
					{
						const min = Math.min(this.state.selectionStartYW, this.state.selectionEndYW);
						const max = Math.max(this.state.selectionStartYW, this.state.selectionEndYW);

						const params: Week["props"] =
						{
							yw,
							selected: (min <= yw && yw <= max),
							parentCapacityCalendar: this,
						};

						return <Week key = {yw} {...params} />;
					}
				})
			}
			</div>
		</div>
		);
	}




	/**
	 * The smaller value between SelectionStartYW and SelectionEndYW.
	 */
	get selectionMin()
	{
		return Math.min(this.state.selectionStartYW, this.state.selectionEndYW);
	}




	/**
	 * The bigger value between SelectionStartYW and SelectionEndYW.
	 */
	get selectionMax()
	{
		return Math.max(this.state.selectionStartYW, this.state.selectionEndYW);
	}




	/**
	 * Clear the selection and edition parameters which leads to closing editor modal and deselecting the cells.
	 */
	modelOnClose()
	{
		this.setState(
		{
			selectionEndYW: -1,
			selectionStartYW: -1,
			editionCapacity: undefined,
			editionCapacityCompany: undefined,
		});
	}




	/**
	 * Event handler runs after Insert/Update of a model.
	 */
	editorOnSuccess()
	{
		this.modelOnClose();
		this.fetchModels();
	}




	/**
	 * Include a year in the calendar and reFetch the models.
	 * @param year New value to be included.
	 */
	addToYears(year: number)
	{
		const {years} = this.state;

		if (!years.includes(year))
		{
			years.push(year);
			this.setState({years});

			this.fetchModels(years);
		}
	}




	/**
	 * Main render function
	 */
	render()
	{
		const {years, isSelectionRunning} = this.state;
		return (
		<div
			className = "CapacityCalendar"
			onMouseDown = {isSelectionRunning ? undefined : this.onMouseDownBound}
			onMouseOver = {this.onMouseOverBound}
			onClick = {this.onClickBound}
		>

			<Button as = "a" href="#capacity-requests">
				<Icon name="list alternate"/> {I18N.LIST_VIEW}
			</Button>

			{
				User.Instance.company_type === 2
				&&
				<Message icon warning>
					<Icon name="warning"/>
					<Message.Content>
						{I18N.PARTNER_COMPANIES_CANNOT_CREATE_CAPACITY_REQUESTS}<br/>
					</Message.Content>
				</Message>
			}

			<Segment>
				<Grid divided columns="2">
					<Grid.Row>
						<Grid.Column>
							<Checkbox
								label={I18N.SENT_OFFERS} defaultChecked
								onChange = {(event, data) => this.setState({filterOut0: data.checked})}
							/><br/>
							<Checkbox
								label={I18N.SENT_REQUESTS} defaultChecked
								onChange = {(event, data) => this.setState({filterOut1: data.checked})}
							/><br/>
							<Checkbox
								label={I18N.RECEIVED_OFFERS} defaultChecked
								onChange = {(event, data) => this.setState({filterIn0: data.checked})}
							/><br/>
							<Checkbox
								label={I18N.RECEIVED_REQUESTS} defaultChecked
								onChange = {(event, data) => this.setState({filterIn1: data.checked})}
							/><br/>
						</Grid.Column>
						<Grid.Column>
							<b>{I18N.SERVICES}:</b><Emsp/>
							{this.renderServiceButtons()}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>

			{ years.map((value) => this.renderWeeks(value)) }
			{ this.renderNextYearbutton() }

			<Legend
				highlighted={this.state.highlighted}
				models={this.state.models}
			/>

			{ this.renderModal() }
		</div>);
	}




	renderServiceButtons()
	{
		return this.state.serviceFilters.map((serviceFilter, index) =>
		<Button
			size = "tiny"
			basic = {!serviceFilter.active}
			key = {serviceFilter.service.id}
			primary = {serviceFilter.active}
			onClick = {() =>
			{
				const serviceFilters = this.state.serviceFilters;
				serviceFilters[index].active = !serviceFilters[index].active;
				this.setState({serviceFilters});
			}}
		>
			{serviceFilter.service.name}
		</Button>);
	}




	/**
	 * Render the editor modal IF an edition model is available.
	 */
	renderModal()
	{
		const {editionCapacity, editionCapacityCompany} = this.state;

		if (editionCapacityCompany !== undefined || editionCapacity !== undefined)
		{
			return (
			<Modal
				key={ this.state.editorKey }
				open={true}
				closeOnEscape={true}
				closeOnDimmerClick={true}
				onClose = {this.modelOnClose.bind(this)}
				basic
			>
				<Modal.Content>
					{
						editionCapacity !== undefined
						&&
						<CapacityEdit model = {editionCapacity} onSuccess = {this.editorOnSuccess.bind(this)} />
					}

					{
						editionCapacityCompany !== undefined
						&&
						<CapacityCompanyEdit model = {editionCapacityCompany} onSuccess = {this.editorOnSuccess.bind(this)} />
					}
				</Modal.Content>
			</Modal>);
		}
	}




	/**
	 * Render the button for appending a year to calendar.
	 */
	renderNextYearbutton()
	{
		const lastYear = _.last(this.state.years);

		return (
		<div className="renderNextYearButton">
			<Button
				basic size="massive"
				onClick = {() => this.addToYears(lastYear + 1)}
			>
				{lastYear + 1}
			</Button>
		</div>);
	}
}
