import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Divider, Form, Icon, Popup, Segment } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import IModelService from "../../model/IModelService";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import FieldForModel from "../for-model/FieldForModel";
import ServiceCRUD from "./ServiceCRUD";
import "./Style.scss";




interface IProps
{
	model: IModelService;
	disabled: boolean;
	parent: ServiceCRUD;
}




interface IState
{
	isSaving: boolean;
	errors: IModelYiiFieldError[];
}




export default class SingleServiceCRUD extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();

	state =
	{
		isSaving: false,
		errors: [],
	};




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
		const formData = new FormData(formEvent.target as HTMLFormElement);
		const {model, parent} = this.props;

		const query: any = {"r": "active/service/create"};

		if (model !== undefined && model.id !== undefined)
		{
			query.r = "active/service/update";
			query.id = model.id;
		}

		this.setState({isSaving: true});

		new Fetcher<IModelService>(query)
		.post(formData)
		.appendTo(this.fetchers)
		.onSuccess(() =>
		{
			this.setState({isSaving: false});
			parent.fetchModels();
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors, isSaving: false});
		})
		.run();
	}




	render()
	{
		const isCreating = (this.props.model.id === undefined);
		const p =
		{
			disabled: this.state.isSaving || this.props.disabled,
			errors: this.state.errors,
			model: this.props.model,
		};

		return (
		<Segment className="SingleServiceCRUD">

			<Form onSubmit={this.formOnSubmit.bind(this)}>

				{
					!!p.model.parent_id
					&&
					<input type="hidden" name="parent_id" value={p.model.parent_id} />
				}

				<FieldForModel<IModelService> {...p}
					field = "name"
					control = {<Form.Input size="huge" placeholder = {I18N.NAME} />}
				/>

				<Divider />

				<FieldForModel<IModelService> {...p}
					field = "description"
					control =
					{<TextareaAutosize minRows = {2} rows = {2} placeholder = {I18N.DESCRIBE_IT}/>}
				/>

				<Button.Group
					labeled
					size="small"
				>
					<Button primary type="submit" disabled={p.disabled} loading={this.state.isSaving}>
						<Icon name = {isCreating ? "add" : "edit"} />
						{isCreating ? I18N.ADD_NEW : I18N.SAVE}
					</Button>

					{
						this.props.model.depth <= 1
						&&
						!isCreating
						&&
						<Popup
							content = {`There are ${this.props.model.childrenCount} services in this sector.`}
							trigger =
							{
								<Button {...p}
									secondary
									type="reset"
									onClick = {() => window.location.hash = `#services_${this.props.model.id}`}
								>
									<Icon name = "list" />
									{I18N.SERVICES}
								</Button>
							}
						/>
					}

					{
						!isCreating
						&&
						<Button {...p}
							onClick = {this.deleteModel.bind(this)}
							type="reset"
						>
							<Icon name="trash alternate"/> {I18N.DELETE}
						</Button>
					}
				</Button.Group>



			</Form>

		</Segment>);
	}




	deleteModel()
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({isSaving: true});

			new Fetcher({"r": "active/service/delete", "id": this.props.model.id})
			.delete()
			.delay(500)
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.props.parent.fetchModels();
			})
			.run();
		}
	}
}
