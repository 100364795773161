import * as React from "react";
import { Button, Divider, Form, Icon, Message, Segment } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import User from "../../common/User";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";
import FieldForModel from "../for-model/FieldForModel";




type Phase = "form" | "form-waiting" | "success";


interface IState
{
	phase: Phase;
	errors: IModelYiiFieldError[];
}




export default class ResetPassword extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		phase: "form" as Phase,
		errors: [],
	};




	componentDidMount()
	{
		if (!User.IsGuest) // If user has already logged in
		{
			this.goToHomePage();
		}
	}




	goToHomePage()
	{
		window.location.hash = "#launcher";
	}




	submitForm(form: HTMLFormElement)
	{
		this.setState({phase: "form-waiting"});

		const data = new FormData(form);

		PrettyPrintFormData(data);

		new Fetcher({"r": "node/auth/reset-password"})
		.post(data)
		.appendTo(this.fetchers)
		.onSuccess(() =>
		{
			this.setState({phase: "success", errors: []});
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({phase: "form", errors});
		})
		.run();
	}




	renderForm()
	{
		const waiting = this.state.phase === "form-waiting";
		const {errors} = this.state;

		return (
		<Form onSubmit={(e: any) => this.submitForm(e.target)}>

			<FieldForModel<{email: string}>
				errors = {errors}
				field = "email"
				control = {
					<Form.Input
						placeholder = {I18N.EMAIL}
						icon = "mail"
						iconPosition="left"
					/>
				}
			/>

			<Button primary type="submit" loading={waiting} disabled={waiting}>
				{I18N.RESET_PASSWORD}
			</Button>
		</Form>);
	}




	renderSuccessMessage()
	{
		return (
		<Message icon>
			<Icon name="inbox"/>

			<Message.Content>
				<Message.Header>{I18N.CHECK_YOUR_EMAIL_FOR_FURTHER_INSTRUCTIONS}!</Message.Header>
				{
					// tslint:disable-next-line: max-line-length
					I18N.IF_YOUR_EMAIL_ADDRESS_EXISTS_IN_OUR_DATABASE_YOU_WILL_RECEIV__125
				}
			</Message.Content>
		</Message>);
	}




	render()
	{
		return (
		<div className="ResetPassword wrapper">
			<Segment>

				{
					(this.state.phase === "form" || this.state.phase === "form-waiting")
					&&
					this.renderForm()
				}

				{
					this.state.phase === "success"
					&&
					this.renderSuccessMessage()
				}

				<Divider/>

				<span>{I18N.ALREADY_HAVE_USERNAME_AND_PASSWORD}? <a href="#login">{I18N.LOGIN}</a></span>

			</Segment>
		</div>);
	}
}
