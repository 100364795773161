import * as React from "react";
import { Accordion, Divider, Grid, Header, Icon } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import IModelCompany from "../../model/IModelCompany";
import IApplicationProps from "../application-collection/IApplicationProps";
import AuthImage from "../ui-element/AuthImage";
import Emsp from "../ui-element/Emsp";
import PreWrapText from "../ui-element/PreWrapText";
import Spacer from "../ui-element/Spacer";
import FormViewUi from "./../ui-element/FormViewUi";
import "./Style.scss";




interface IState
{
	model: Partial<IModelCompany>;
	activeDepartmentIndex: number;
}




export default class CompanyView extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		model: undefined as IModelCompany,
		activeDepartmentIndex: 0,
	};




	componentDidMount()
	{
		const id = parseInt(this.props.optionsString);

		if (!isNaN(id))
		{
			this.fetchModel(id);
		}
	}




	fetchModel(id: number)
	{
		this.setState({model: undefined});

		new Fetcher({
			"r": "active/company/view",
			"expand": [
				"canUpdate", "canDelete", "contactUser",
				"location", "departmentsCount", "companies.canDelete",
				"companies.canUpdate", "companyServices.service",
				"companies.companyServices.service",
			].join(","),
			id,
		})
		.get()
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({model: data});
		})
		.run();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	render()
	{
		return (
		<React.Fragment>
			{
				this.state.model === undefined
				?
				<div className="wrapper">
					{ FormViewUi.Waiting() }
				</div>
				:
				this.renderSingle()
			}
		</React.Fragment>);
	}




	renderSingle()
	{
		const {model} = this.state;

		const imageId = model.logo_file_id && model.cover_file_id;
		const address = this.makeAddress(model);

		return (
		<div className="wrapper CompanyView">

			{FormViewUi.BackButton(I18N.COMPANIES, "#companies")}

			<Header as="h2">
				{ model.name }
				<Header.Subheader>
				{
					(model.location && model.location.title) &&
					<span><Icon name="map marker alternate" /> {model.location.title}<Emsp/></span>
				}
				{
					(!isNaN(model.number_of_employees) && model.number_of_employees > 0 ) &&
					<span><Icon name="users" /> {model.number_of_employees}<Emsp/></span>
				}
				{
					(!isNaN(model.departmentsCount) && model.departmentsCount > 0 ) &&
					<span><Icon name="sitemap" /> {model.departmentsCount}<Emsp/></span>
				}
				</Header.Subheader>
			</Header>

			<Grid>
				<Grid.Row>
					<Grid.Column width = {10}>
						<PreWrapText content = {model.description} />
					</Grid.Column>

					<Grid.Column width = {6}>
					{
						model.equipment &&
						<div className="detail">
							<h3>{I18N.EQUIPMENT}:</h3>
							<PreWrapText content = {model.equipment} as = "span"/>
						</div>
					}

					{
						Array.isArray(model.companyServices) && model.companyServices.length > 0
						&&
						<div className="detail">
							<h3>{I18N.SERVICES}:</h3>
							{
								model.companyServices.map((value) => value.service.name).join(", ")
							}
						</div>
					}

					{
						address &&
						<div className="detail">
							<PreWrapText content = {address} />
						</div>
					}
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<Spacer/>

			{ imageId ? <AuthImage id={imageId}/> : <Divider/>}

			<Spacer/>

			{
				Array.isArray(model.companies) !== undefined && model.companies.length > 0
				&&
				<>
				<Header as = "h4">{I18N.DEPARTMENTS}</Header>
				<Accordion fluid styled>
				{
					model.companies.map((department, index) =>
					<React.Fragment key = {index}>
						<Accordion.Title
							active={this.state.activeDepartmentIndex === index}
							index={index}
							onClick={
								() => this.setState({
									activeDepartmentIndex: this.state.activeDepartmentIndex === index ? -1 : index,
								})
							}
						>
							<h4><Icon name="dropdown" /> {department.name}</h4>
						</Accordion.Title>
						<Accordion.Content active={this.state.activeDepartmentIndex === index}>
							<Grid>
								<Grid.Row>
									<Grid.Column width = {10}>
										<PreWrapText content = {department.description} />
									</Grid.Column>

									<Grid.Column width = {6}>
									{
										department.equipment &&
										<div className="detail">
											<h5>{I18N.EQUIPMENT}:</h5>
											<PreWrapText content = {department.equipment} as = "span"/>
										</div>
									}

									{
										Array.isArray(department.companyServices) && department.companyServices.length > 0
										&&
										<div className="detail">
											<h5>{I18N.SERVICES}:</h5>
											{
												department.companyServices.map((value) => value.service.name).join(", ")
											}
										</div>
									}

									{
										this.makeAddress(department) &&
										<div className="detail">
											<PreWrapText content = {this.makeAddress(department)} />
										</div>
									}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Accordion.Content>
					</React.Fragment>)
				}
				</Accordion>
				</>
			}
		</div>);
	}




	makeAddress(model: IModelCompany)
	{
		const output = [] as string[];

		if (model.address) output.push(model.address + "\n");

		if (model.zip_code) output.push(model.zip_code);
		if (model.city) output.push(model.city + "\n");

		if (model.country) output.push(model.country + "\n");

		if (model.phone) output.push(`<b>${I18N.PHONE_NUMBER}: </b>${model.phone}\n`);

		if (model.website) output.push(`<b>${I18N.WEBSITE}: </b>${model.website}\n`);

		if (output.length === 0)
			return undefined;
		else
			return output.join(" ");
	}
}
