export default function MakeDataAttributeObject(input: any): Readonly<any>
{
	const output = {} as any;

	for (const key in input)
	{
		if (input.hasOwnProperty(key))
		{
			output["data-" + key] = input[key];
		}
	}

	return output;
}
