// import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import IsDev from "./app/common/IsDev";
import ApplicationCollection from "./app/node/application-collection/ApplicationCollection";
import * as serviceWorker from "./serviceWorker";

if (!IsDev() && window.location.protocol !== "https:")
{
	window.location.protocol = "https:";
}

ReactDOM.render(<ApplicationCollection />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
