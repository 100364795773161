import moment from "moment";
import * as React from "react";
import { Button, Header, Label, Segment, Tab, TabPane } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import IModelCapacityCompany from "../../model/IModelCapacityCompany";
import DecodeYW from "../capacity-calendar/DecodeYW";
import WaitingUiElement from "../ui-element/WaitingUiElement";




interface IProps
{
	type: 0|1;
	pane?: boolean;
	order: number;
}




interface IState
{
	models: IModelCapacityCompany[];
	phase: PhaseFlags;
}




export default class InCapacities extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		models: [] as IModelCapacityCompany[],
		phase: PhaseFlags.NONE,
	};




	componentDidMount()
	{
		this.fetchModels();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchModels()
	{
		this.setState({phase: PhaseFlags.FETCHING});

		new Fetcher<IModelCapacityCompany[]>
		(
			{
				"r": "node/capacity-crud/in-capacities",
				"type": this.props.type,
				"order": this.props.order,
			},
		)
		.get()
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) => this.setState({models: data, phase: PhaseFlags.NONE}))
		.onError(() => this.setState({models: [] as any, phase: PhaseFlags.NONE}))
		.run();
	}




	render()
	{
		const Wrapper = this.props.pane ? Tab.Pane : Segment;

		return (
		<Wrapper>
		{
			this.state.phase === PhaseFlags.NONE
			?
			this.renderModels(this.state.models)
			:
			WaitingUiElement.Tree()
		}
		</Wrapper>);
	}




	renderModels(models: IModelCapacityCompany[])
	{
		if (models.length > 0)
		{
			const unprocessed = models.filter(m => m.status === 0);
			const approved = models.filter(m => m.status === 1);
			const rejected = models.filter(m => m.status === 2);

			const panes = [
				{ menuItem: I18N.WAITING_FOR_RESPONSE, render: () => <Tab.Pane>{this.renderTab(unprocessed)}</Tab.Pane> },
				{ menuItem: I18N.APPROVED, render: () => <Tab.Pane>{this.renderTab(approved)}</Tab.Pane> },
				{ menuItem: I18N.REJECTED, render: () => <Tab.Pane>{this.renderTab(rejected)}</Tab.Pane> },
			  ]

			return <Tab panes={panes} />

			
		}
		else
  		{
			return <p>{I18N.THERE_IS_NO_REQUEST_IN_THIS_CATEGORY}.</p>;
		}
	}

	renderTab(models) {
		return models.map((model) => {
			let newCapacity = Math.round(new Date().getTime() / 1000) - model.created_at - 48 * 60 * 60 < 0;

			return 	(<Segment key={model.id} vertical>
				{newCapacity && <Label color='teal' ribbon>{I18N.NEW}</Label>}
				{
					model.status === 1 && model.capacity.company !== null
					&&
					<h2>{model.capacity.company.name}</h2>
				}

				<Header as="h3">

					{model.capacity.service.name}

					<div style={{float: "right"}}>
						{this.renderStatusAction(model)}
					</div>

					{this.renderTimeSpan(model.capacity.start_year_week, model.capacity.end_year_week)}
				</Header>

				{ this.renderComment(I18N.PUBLIC_COMMENT, model.capacity.public_comment) }

			</Segment>)
		});
	}



	renderTimeSpan(start_year_week: number, end_year_week: number)
	{
		const arr = [];

		const format = "Do MMM YYYY [(kw] W [)]";

		{
			const {year, week} = DecodeYW(start_year_week);
			arr.push(moment().year(year).isoWeek(week).weekday(0).format(format));
		}
		{
			const {year, week} = DecodeYW(end_year_week);
			arr.push(moment().year(year).isoWeek(week).weekday(6).format(format));
		}

		return (
		<Header.Subheader>
			{arr.join(" - ")}
		</Header.Subheader>);
	}




	renderComment(title: string, content: string)
	{
		if (!!content)
		{
			return (
			<p>
				<b>{title}: </b>
				{ content }
			</p>);
		}
	}




	renderStatusAction(model: IModelCapacityCompany)
	{
		if (model.status === 0)
		{
			return (
			<Button.Group>
				<Button
					color="green"
					onClick = {() => this.updateStatus(model.id, 1)}
				>
					{I18N.APPROVE}
				</Button>

				<Button
					color="orange"
					onClick = {() => this.updateStatus(model.id, 2)}
				>
					{I18N.REJECT}
				</Button>
			</Button.Group>);
		}
		else if (model.status === 1)
		{
			return (
			<Label color="green">
				{I18N.APPROVED}
			</Label>
			);
		}
		else
		{
			return (
			<Label color="orange">
				{I18N.REJECTED}
			</Label>
			);
		}
	}




	updateStatus(id: string | number, status: number)
	{
		const data = new FormData();

		data.set("status", status.toString());

		new Fetcher({"r" : "active/capacity-company/update", id})
		.post(data)
		.appendTo(this.fetchers)
		.onSuccess(() => this.fetchModels())
		.run();
	}
}
