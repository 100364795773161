import * as React from "react";
import { Button, Form, Icon, Message, Progress } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import IModelFile from "../../model/IModelFile";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";




interface IState
{
	isSaving: boolean;
	progress: number;
	errors: IModelYiiFieldError[];
}




export default class FileUpload extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		isSaving: false,
		progress: 0,
		errors: [],
	};




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	submitForm(form: HTMLFormElement)
	{
		this.setState({isSaving: true});

		const formData = new FormData(form);
		PrettyPrintFormData(formData);

		new Fetcher<IModelFile>({"r": "node/file-upload"})
		.appendTo(this.fetchers)
		.post(formData)
		.config(
		{
			headers:
			{
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: (progressEvent) =>
			{
				const progress = (progressEvent.loaded * 100) / progressEvent.total;

				this.setState({progress});
			},
		})
		.onSuccess(({data}) =>
		{
			this.setState({isSaving: false, errors: []});
			window.location.hash = "#file-edit_" + data.id;
		})
		.onYiiFieldError((data) =>
		{
			this.setState({isSaving: false, progress: 0, errors: data});
		})
		.run();
	}




	render()
	{
		const {isSaving, progress, errors} = this.state;

		return (
		<Form
			onSubmit={(e: any) => this.submitForm(e.target)}
			className = "wrapper"
		>
			{
				(isSaving || progress !== 0)
				&&
				<Progress percent={this.state.progress} indicating size="small" />
			}

			{
				(!isSaving && progress === 0)
				&&
				<Form.Input
					type="file"
					name="RequestUploadFile[file]"
					accept="image/png, image/jpeg"
				/>
			}

			{
				errors.length > 0
				&&
				<Message icon negative>
					<Icon name="warning sign"/>

					<Message.Content>
						<Message.Header>Error!</Message.Header>
						<Message.List>
							{
								this.state.errors.map( (error, index) => <Message.Item key = {index}>{error.message}</Message.Item> )
							}
						</Message.List>
					</Message.Content>
				</Message>
			}

			<Button
				primary
				type="submit"
				loading={this.state.isSaving}
				disabled={this.state.isSaving || this.state.progress !== 0}
			>
				{"Upload"}
			</Button>
		</Form>
		);
	}
}
