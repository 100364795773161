import * as React from "react";
import { DropdownItemProps, DropdownProps, Form, SelectProps } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";



interface IProps<MODEL_TYPE> extends Partial<SelectProps>
{
	query: any;
	select?: (data: any) => MODEL_TYPE[];
	map: (model: MODEL_TYPE, index: number) => DropdownItemProps;
}



interface IState<MODEL_TYPE>
{
	value: DropdownProps["value"];
	models: MODEL_TYPE[];
}




export default class ModelSelector<MODEL_TYPE>
extends React.Component<IProps<MODEL_TYPE>, IState<MODEL_TYPE>>
{
	state =
	{
		value: this.props.defaultValue,
		models: [] as MODEL_TYPE[],
	};



	fetchers = Fetcher.CreateList();



	componentDidMount(): void
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	async fetchData()
	{
		this.setState({models: []});

		new Fetcher<MODEL_TYPE[]>(this.props.query)
		.get()
		.writeToCacheOnSuccess(true)
		.readFromCacheIfYoungerThan(10 * 1000) // 10 seconds
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({models: data});
		})
		.run();
	}




	render()
	{
		const selectProps = {...this.props};
		delete selectProps.map;
		delete selectProps.query;

		const onChange = this.props.onChange;
		delete selectProps.onChange;

		let models = this.props.select === undefined ? this.state.models : this.props.select(this.state.models);

		delete selectProps.select;

		if (models === undefined)
			models = [];

		return (
			<Form.Field>
				<input
					type="hidden"
					name={this.props.name}
					value=
					{
						(this.state.value === undefined || this.state.value === null)
						?
						""
						:
						this.state.value.toString()
					}
				/>


				<Form.Select
					{...selectProps}

					onChange = {
						(event, data) =>
						{
							this.setState({value: data.value});

							if (onChange !== undefined)
								onChange(event, data);
						}
					}
					options= { models.map(this.props.map) }
				/>
			</Form.Field>
		);

	}
}
