import _ from "lodash";
import * as React from "react";
import {Grid, Header, Icon, Placeholder, Segment} from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";



export default class CardViewUi
{
	static Waiting()
	{
		return (
		<Grid columns={2} stackable>
		{
			_.times(4, (index) =>
			{
				return (
				<Grid.Column key={index}>
					<Segment>
						<Placeholder>

							<Placeholder.Header>
								<Placeholder.Image rectangular />
								<Placeholder.Line />
								<Placeholder.Line />
							</Placeholder.Header>

							<Placeholder.Paragraph>
								<Placeholder.Line/>
								<Placeholder.Line/>
								<Placeholder.Line/>
								<Placeholder.Line/>
							</Placeholder.Paragraph>

						</Placeholder>
					</Segment>
				</Grid.Column>);
			})
		}
		</Grid>);
	}



	static AddNew(href: string)
	{
		return (
		<a className="ui placeholder segment" href={href}>
			<Header icon>
				<Icon name="plus" />{I18N.ADD_NEW}
			</Header>
		</a>);
	}
}
