export default class LocationHash
{
	targetString: string;




	constructor(hash?: string)
	{
		this.targetString = (hash === undefined ? window.location.hash : hash);
	}




	extractPart()
	{
		const regex: RegExp = /^(#?)([^#_][^_]*)(_(.*))?$/m;

		const match = regex.exec(this.targetString);

		const output = {
			applicationUri: undefined as string,
			optionsString: undefined as string,
		};

		if (match !== null)
		{
			output.applicationUri = this.sanitize(match[2]);
			output.optionsString = this.sanitize(match[4]);
		}

		return output;
	}



	get applicationUri()
	{
		return this.extractPart().applicationUri;
	}



	get optionsString()
	{
		return this.extractPart().optionsString;
	}



	private sanitize(str: any): string
	{
		if (typeof str === "string")
		{
			if (str.trim() === "")
				return undefined;
			else
				return str;
		}
		else
		{
			return undefined;
		}
	}
}
