import * as React from "react";
import { Button, ButtonProps, Card, Icon, Image, Label } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import QueryUrl from "../../common/QueryUrl";
import User from "../../common/User";
import IModelFile from "../../model/IModelFile";
import IApplicationProps from "../application-collection/IApplicationProps";
import CardViewUi from "../ui-element/CardViewUi";
import PreWrapText from "../ui-element/PreWrapText";




interface IState
{
	isWaiting: boolean;
	models: IModelFile[];
	deletingId: number;
}




export default class FileList extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		isWaiting: false,
		models: [],
		deletingId: -1,
	};




	componentDidMount()
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchData()
	{
		this.setState({isWaiting: true, models: []});

		new Fetcher<IModelFile[]>({
			"r": "active/file/index",
			"expand": ["canUpdate", "canDelete", "company"].join(","),
		})
		.appendTo(this.fetchers)
		.get()
		.delay(500)
		.onSuccess(({data}) =>
		{
			this.setState({isWaiting: false, models: data});
		})
		.run();
	}




	renderElement(model: IModelFile, index: number)
	{
		const usage = [];

		const hasCompany = model.company !== null && model.company !== undefined;
		const isLogoFile = hasCompany && model.id === model.company.logo_file_id;
		const isCoverFile = hasCompany && model.id === model.company.cover_file_id;
		const isAdFile = hasCompany && model.id === model.company.advertisement_file_id;

		if (model.company !== null)
		{
			if (model.company.logo_file_id === model.id)
			{
				usage.push(I18N.COMPANYS_LOGO);
			}

			if (model.company.cover_file_id === model.id)
			{
				usage.push(I18N.COMPANYS_COVER);
			}
		}


		return (
		<Card fluid key={model.id}>

			<Image src = {QueryUrl({"r": "node/file-download/index", "id": model.id})} />

			<Card.Content>

				<Card.Header>{model.title}</Card.Header>

				<Card.Meta>
					{
						model.company !== null
						&&
						<p><Icon name="building" /> {model.company.name}</p>
					}

					{
						isLogoFile
						&&
						<Label size="mini" ribbon color="teal">{I18N.COMPANYS_LOGO}</Label>
					}

					{
						isCoverFile
						&&
						<Label size="mini" ribbon color="blue">{I18N.COMPANYS_COVER}</Label>
					}

					{
						isAdFile
						&&
						<Label size="mini" ribbon color="orange">Ad</Label>
					}

				</Card.Meta>

				{
					model.link_to
					&&
					<Card.Description>
						{ I18N.URL }: <a href={ model.link_to } target="_blank" rel="noopener noreferrer">{ model.link_to }</a>
					</Card.Description>
				}


				<Card.Description><PreWrapText content={model.description}/> </Card.Description>

			</Card.Content>

			{ this.renderModifyButtons(model) }

		</Card>);
	}




	renderModifyButtons(model: IModelFile)
	{
		if (model.canUpdate || model.canDelete)
		{
			const d = this.state.deletingId;

			const props: ButtonProps =
			{
				basic: true,
				disabled: d !== -1,
				loading:  d !== -1 && model.id === d,
			};

			return (
			<Card.Content extra>
				<Button.Group
					labeled
					icon
					size="mini"
				>
					{
						model.canUpdate // && model.can_edit["any"]
						&&
						<Button {...props}
							color="green"
							onClick={() => window.location.hash = `#file-edit_${model.id}`}
						>
							<Icon name="edit"/> {I18N.EDIT}
						</Button>
					}
					{
						model.canDelete
						&&
						<Button {...props}
							color="red"
							onClick = {() => this.deleteModel(model)}
						>
							<Icon name="trash alternate"/> {I18N.DELETE}
						</Button>
					}
				</Button.Group>
			</Card.Content>
			);
		}

		return undefined;
	}




	deleteModel(model: IModelFile)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({deletingId: model.id});

			new Fetcher({"r": "node/file-list/delete", "id": model.id})
			.delete()
			.delay(500)
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.setState({deletingId: -1});
				this.fetchData();
			})
			.run();
		}
	}




	render()
	{
		return (
			<div className="wrapper">
				{
					this.state.isWaiting
					?
					CardViewUi.Waiting()
					:
					<Card.Group>
						{
							this.state.models.map( this.renderElement.bind(this) )
						}

						{
							(User.IsAdmin || User.IsGod)
							&&
							CardViewUi.AddNew("#file-upload")
						}
					</Card.Group>
				}
			</div>
		);
	}
}
