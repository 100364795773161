import * as React from "react";
import { toast } from "react-toastify";
import { Button, Dimmer, Divider, Form, Icon, Label, Loader, Message, Segment } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import IsDev from "../../common/IsDev";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import User from "../../common/User";
import IModelUserAuth from "../../model/IModelUserAuth";
import IApplicationProps from "../application-collection/IApplicationProps";




interface IState
{
	isLoading: boolean;
	mode: undefined|"session"|"login-form";
	error: string;
}




export default class Login extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();

	state =
	{
		isLoading: false,
		mode: undefined,
		error: "",
	};




	componentDidMount()
	{
		this.takeLoginStep();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	goToHomePage()
	{
		const link = this.props.optionsString === undefined ? "launcher" : this.props.optionsString;
		window.location.hash = link;
	}




	/**
	 * The main login handler.
	 */
	takeLoginStep()
	{
		if (!User.IsGuest) // If user has already logged in
		{
			this.goToHomePage();
		}
		else
		{
			if (User.HasSessionKey()) // Try to load user data
			{
				this.runSessionLogin();
			}
			else // Display Loggin form
			{
				this.setState({isLoading: false, mode: "login-form"});
				// if (IsDev()) this.doLogin();
			}
		}
	}




	/**
	 * Try to do the login by auth-key from session.
	 */
	async runSessionLogin()
	{
		this.setState({isLoading: true, mode: "session"});

		await new Fetcher<IModelUserAuth>({"r": "node/login/current-auth"})
		.get()
		.appendTo(this.fetchers)
		.config(
		{
			headers: {Authorization: `Bearer ${User.GetSessionKey()}`},
		})
		.onSuccess(({data}) =>
		{
			User.Login(data);
		})
		.onError(() =>
		{
			User.RemoveSessionKey();
		})
		.run();

		this.setState({isLoading: false, mode: undefined});

		this.takeLoginStep();
	}




	submitForm(form: HTMLFormElement)
	{
		const formData = new FormData(form);

		this.setState({isLoading: true});

		PrettyPrintFormData(formData);

		new Fetcher<IModelUserAuth>({"r": "node/auth/with-login-data"})
		.post(formData)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			User.Login(data);
			this.takeLoginStep();
		})
		.onYiiException((error) =>
		{
			console.log(error.message);
			this.setState({error: error.message, isLoading: false});
		})
		.onError(() =>
		{
			toast.error(I18N.AN_UNEXPECTED_ERROR_HAS_HAPPENED_PLEASE_REFRESH_THIS_PAGE_AN__71);
			this.setState({isLoading: false});
		})
		.run();
	}




	renderLoginForm(modelClass: string)
	{
		return (
		<Segment>

			<Form onSubmit={(e: any) => this.submitForm(e.target)}>

				<Form.Input fluid
					icon="mail"
					iconPosition="left"
					placeholder={I18N.EMAIL}
					name={ `${modelClass}[email]` }
					disabled={this.state.isLoading}
					error = {this.state.error !== ""}
					defaultValue={IsDev("jch@d2p.ch")}
					autoComplete="email"
				/>

				<Form.Input fluid icon="lock" iconPosition="left" placeholder={I18N.PASSWORD} type="password"
					name={ `${modelClass}[password]` }
					disabled={this.state.isLoading}
					error = {this.state.error !== ""}
					defaultValue={IsDev("")}
					autoComplete="current-password"
				/>

				<Button type="submit" loading={this.state.isLoading} disabled={this.state.isLoading} primary>{I18N.LOGIN}</Button>

				{
					this.state.error !== ""
					&&
					<Label basic color="red" pointing="left">{this.state.error}</Label>
				}
			</Form>

			<Divider/>

			<a href="#reset-password">{I18N.FORGOT_YOUR_PASSWORD}?</a>

		</Segment>);
	}



	renderMessage()
	{
		return (
		<Message icon>
			<Icon name="help"/>

			<Message.Content>
				{
					<Message.Header>{I18N.ENTER_YOUR_LOGIN_INFORMATION}?</Message.Header>
				}
				{I18N.THE_PASSWORD_FIELD_IS_CASESENSITIVE}<br/>
			</Message.Content>
		</Message>);
	}



	render()
	{
		const modelClass = "RequestAuth";

		return (
			<div className="Login wrapper">
				{ this.renderMessage() }

				{
					(this.state.mode !== "login-form")
					&&
					<Dimmer active inverted>
						<Loader inverted>Loading</Loader>
					</Dimmer>
				}

				{
					(this.state.mode === "login-form")
					&&
					this.renderLoginForm(modelClass)
				}
			</div>
		);
	}
}
