import * as React from "react";
import { Form, FormInputProps, Label } from "semantic-ui-react";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";




interface IProps<MODEL_TYPE>
{
	model?: MODEL_TYPE;
	errors?: IModelYiiFieldError[];

	field: keyof MODEL_TYPE;

	disabled?: boolean;
	required?: boolean;
	label?: string;
	control: React.ReactElement<FormInputProps>;
}




export default class FieldForModel<MODEL_TYPE>
extends React.Component<IProps<MODEL_TYPE>>
{
	static get isFieldForModel()
	{
		return true;
	}




	getDefaultValue(field: keyof MODEL_TYPE)
	{
		if (this.props.model !== undefined && field in this.props.model)
		{
			return this.props.model[field];
		}

		return undefined;
	}




	hasError()
	{
		for (const key in this.props.errors)
			if (this.props.errors[key].field === this.props.field)
				return true;

		return false;
	}




	render()
	{
		const newProps: any = {};

		newProps.name = this.props.field;

		const defaulValue = this.getDefaultValue(this.props.field);

		if (defaulValue !== undefined)
			newProps.defaultValue = defaulValue;

		const disabled = this.props.disabled;

		return (
		<Form.Field disabled = {disabled}>

			{
				this.props.label !== undefined
				&&
				<label>
				{this.props.label}
				{
					this.props.required
					&&
					<span style={{"color": "red"}}>*</span>
				}
				</label>
			}

			{React.cloneElement(this.props.control, newProps)}

			{
				this.hasError()
				&&
				<Label basic color="red" pointing>
					{
						(this.props.errors as Array<{field: string, message: string}>).map((error, id) =>
						{
							return error.field === this.props.field && <p key={id}> {error.message} </p>;
						})
					}
				</Label>
			}
		</Form.Field>);
	}
}
