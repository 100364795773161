import * as React from "react";
import { Button, Icon, Input, Message, Segment, SemanticCOLORS } from "semantic-ui-react";
import * as stringSimilarity from "string-similarity";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import IModelCompany from "../../model/IModelCompany";
import IApplicationProps from "../application-collection/IApplicationProps";
import CardViewUi from "../ui-element/CardViewUi";
import Spacer from "../ui-element/Spacer";
import SingleCompany from "./SingleCompany";




interface ISearchCriteria
{
	id: string;
	title: string;
	color: string;
	value: boolean;
}




interface IState
{
	models: IModelCompany[];
	isWaiting: boolean;
	searchString: string;
	searchFields: ISearchCriteria[];
	deletingId: number;
}




export default class CompanyList extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();

	state =
	{
		isWaiting: false,
		deletingId: -1,
		models: [],
		searchString: "",
		searchFields:
		[
			{id: "name",			title: I18N.NAME,			color: "blue",	value: true	},
			{id: "description",		title: I18N.DESCRIPTION,	color: "blue", value: true	},
			{id: "contact",			title: I18N.CONTACT,		color: "blue",	value: true	},
			{id: "region",			title: I18N.REGION,			color: "blue",	value: true	},
			{id: "equipment",		title: I18N.EQUIPMENT,		color: "blue",	value: true	},
		],
	};




	componentDidMount()
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchData()
	{
		this.setState({isWaiting: true, models: []});

		new Fetcher<IModelCompany[]>({
			"r": "active/company/index",
			"expand": [
				"canUpdate", "canDelete", "contactUser", "location",
				"departmentsCount", "companies.canDelete", "companies.canUpdate",
			].join(","),
			"filter[parent_id]": null,
		})
		.get()
		.appendTo(this.fetchers)
		.delay(500)
		.onSuccess(({data}) =>
		{
			this.setState({isWaiting: false, models: data});
		})
		.run();
	}




	doesMatchSearchString(model: IModelCompany)
	{
		const ss = this.state.searchString;
		const sf = this.state.searchFields;

		if (ss === "")
		{
			return true;
		}
		else
		{
			for (const i in sf) if (sf[i].value)
			{
				switch (sf[i].id)
				{
					case "name":
						if (this.stringsAreSimilar(ss, model.name))
							return true;
						break;

					case "description":
						if (this.stringsAreSimilar(ss, model.description))
							return true;
						break;

					case "contact":
						if (
							this.stringsAreSimilar(ss, model.contact) ||
							(model.contactUser && this.stringsAreSimilar(ss, model.contactUser.nickname))
						)
							return true;
						break;

					case "region":
						if (
							model.location &&
							( this.stringsAreSimilar(ss, model.location.title) ||
							this.stringsAreSimilar(ss, model.location.description) )
						)
							return true;
						break;

					case "equipment":
						if (this.stringsAreSimilar(ss, model.equipment))
							return true;
						break;
					default:
						break;
				}
			}
		}

		return false;
	}




	private stringsAreSimilar(ss: string, sm: string)
	{
		try
		{
			sm = sm.trim().toLowerCase();
			return sm.indexOf(ss) !== -1 || stringSimilarity.compareTwoStrings(sm, ss) > 0.4;
		}
		catch
		{
			return false;
		}
	}




	render()
	{
		return (
		<div className="wrapper">
			{ this.renderMessage() }

			{
				this.state.isWaiting
				?
				CardViewUi.Waiting()
				:
				<>
					{ this.renderSearch() }

					<Spacer height = "20px"/>

					{ this.state.models.map( (model) => (
						<SingleCompany
							key = {model.id}
							model = {model}
							display = {this.doesMatchSearchString(model)}
							afterDelete = {this.fetchData.bind(this)}
						/>
					))}

					{ User.IsGod && CardViewUi.AddNew("#company-edit") }
				</>
			}
		</div>
		);
	}




	renderMessage()
	{
		return (
		<Message icon>
			<Icon name="help"/>

			<Message.Content>
				{
					<Message.Header>{I18N.LIST_OF_ALL_THE_AVAILABLE_COMPANIES}</Message.Header>
				}
				{I18N.QUICKLY_SEARCH_IN_THIS_LIST_BY_FILLING_SEARCH_FIELD}<br/>
			</Message.Content>
		</Message>);
	}




	renderSearch()
	{
		return (
		<Segment tertiary>
			<Input
				fluid
				onChange={(event) => this.setState({searchString: event.target.value.trim().toLocaleLowerCase()})}
				style={{margin: "10px 0px"}}
				placeholder={I18N.SEARCH + "..."}
			/>

			<span>{I18N.SEARCH_IN}: </span>

			<Button.Group size="mini" compact>
			{
				this.state.searchFields.map((field, index) =>
				(
					<Button
						onClick={
							() => {
								const searchFields = this.state.searchFields;
								searchFields[index].value = !searchFields[index].value;
								this.setState({searchFields});
							}
						}
						key={field.id}
						basic={!field.value}
						color={field.value ? field.color as SemanticCOLORS : "grey"}
					>
						{field.title}
					</Button>
				))
			}
			</Button.Group>
		</Segment>
		);
	}
}
