import * as React from "react";
import { Icon } from "semantic-ui-react";
import Config from "../../common/Config";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import FeedbackModal from "./Feedback";
import "./Style.scss";




interface IProps
{
	visible: boolean;
	in_launcher?: boolean;
	wide?: boolean;
}



interface IState
{
	wide: boolean;
}




export default class NavigationBar extends React.Component<IProps, IState>
{
	private navRef: React.RefObject<HTMLElement> = React.createRef();




	calculateTop(): string
	{
		if (!this.props.visible && this.navRef.current !== undefined)
			return -(this.navRef.current.clientHeight + 5) + "px";
		else
			return "0px";
	}




	render()
	{
		const {in_launcher, wide} = this.props;


		return (
		<nav
			className="NavigationBar"
			style={{top: this.calculateTop(), zIndex: 100}}
			ref={this.navRef}
			data-in_launcher = {in_launcher}
		>
			<div data-wide = {wide}>

				{
					!in_launcher
					&&
					<h1 className="logo">
						Säntis Innovations<br/>-Cluster Holz
					</h1>
				}

				{
					!User.IsGuest
					&&
					<a href={Config.USER_GUIDE}>
						<Icon name="book" size="large"/>
						<p>{I18N.USER_GUIDE}</p>
					</a>
				}


				{
					!User.IsGuest
					&&
					<FeedbackModal />
				}


				<div>
					<Icon name="globe" size="large"/>

					<div className="title">
						{I18N.LANGUAGE}

						<div className="selector">
							{I18N.Index !== 0 && <a href="#change-language_en">EN</a>}
							{I18N.Index !== 1 && <a href="#change-language_de">DE</a>}
						</div>
					</div>
				</div>

				{
					!in_launcher
					&&
					<a href="#launcher">
						<Icon name="dashboard" size="large"/>
						<p>{I18N.LAUNCHER}</p>
					</a>
				}

				{
					!User.IsGuest
					&&
					<a href="#logout">
						<Icon name="power off" size="large"/>
						<p>{I18N.LOGOUT}</p>
					</a>
				}
			</div>
		</nav>);
	}
}
