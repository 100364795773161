import _ from "lodash";




export default function MapDeepWithParseInt(input: any, clone: boolean = false)
{
	if (clone)
	{
		input = _.cloneDeep(input);
	}

	const inputType = typeof(input);

	if (inputType === "number")
	{
		return input;
	}
	else if (inputType === "string")
	{
		const v = parseInt(input);
		return isNaN(v) || v.toString() !== input ? input : v;
	}
	else if (inputType === "object")
	{
		for (const key in input)
		{
			if (input.hasOwnProperty(key))
			{
				const elementType = typeof(input[key]);

				if (elementType === "object")
				{
					MapDeepWithParseInt(input[key]);
				}
				else
				{
					input[key] = MapDeepWithParseInt(input[key]);
				}
			}
		}
	}

	return input;
}
