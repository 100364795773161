import * as React from "react";




interface IProps
{
	height?: string;
}




export default class Spacer extends React.Component<IProps>
{
	render()
	{
		return <div style = {{height: this.props.height || "50px"}}></div>;
	}
}
