import Axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import {Schema, validate} from "jsonschema";
import IModelYiiException from "../model/IModelYiiException";
import IModelYiiFieldError from "../model/IModelYiiFieldError";
import IsDev from "./IsDev";
import MapDeepWithParseInt from "./MapDeepWithParseInt";
import QueryUrl from "./QueryUrl";
import Sleep from "./Sleep";



interface ICacheRecord
{
	timestamp: number;
	response: AxiosResponse;
}




export default class Fetcher<RESPONSE_TYPE>
{
	private url: string;
	private verb: "post" | "get" | "delete";
	private data: any;
	private requestConfig: AxiosRequestConfig;
	private delayAmount: number;
	private cancelTockenSource: CancelTokenSource;

	private successMethod: (response: AxiosResponse<RESPONSE_TYPE>) => void;

	private yiiExceptionMethod: (data: IModelYiiException) => void;
	private yiiFieldErrorMethod: (data: IModelYiiFieldError[]) => void;
	private defaultErrorMethod: (data: any) => void;

	private yiiExceptionSchema: Schema;
	private yiiFieldErrorSchema: Schema;

	private readFromCacheAgeLimit: number;
	private writeToCache: boolean;


	private fetchersToAppendTo: Array<Fetcher<any>>;




	private static ObjectCache = new Map<string, ICacheRecord>();




	constructor(query: any)
	{
		this.cancelTockenSource = Axios.CancelToken.source();

		this.url = QueryUrl(query);
		this.verb = undefined;
		this.requestConfig = {};
		this.delayAmount = 0;

		this.readFromCacheAgeLimit = 0;
		this.writeToCache = false;

		this.yiiExceptionSchema =
		{
			"title": "data",
			"type": "object",
			"properties":
			{
				"message": {"type": "string"},
			},
			"required": ["message"],
		};

		this.yiiFieldErrorSchema =
		{
			"title": "data",
			"type": "array",
			"items":
			{
				"type": "object",
				"properties":
				{
					"field": { "type": "string" },
					"message": { "type": "string" },
				},
				"required": ["field", "message"],
			},
		};
	}




	get()
	{
		this.verb = "get";
		return this;
	}




	post(data: any = {})
	{
		this.data = data;
		this.verb = "post";
		return this;
	}



	delete()
	{
		this.verb = "delete";
		return this;
	}



	config(config: AxiosRequestConfig)
	{
		this.requestConfig = config;

		return this;
	}



	delay(amount: number)
	{
		this.delayAmount = amount;
		return this;
	}




	onSuccess(method: Fetcher<RESPONSE_TYPE>["successMethod"])
	{
		this.successMethod = method;
		return this;
	}




	onYiiException(method: Fetcher<RESPONSE_TYPE>["yiiExceptionMethod"])
	{
		this.yiiExceptionMethod = method;
		return this;
	}




	onYiiFieldError(method: Fetcher<RESPONSE_TYPE>["yiiFieldErrorMethod"])
	{
		this.yiiFieldErrorMethod = method;
		return this;
	}




	onError(method: (data: any) => void)
	{
		this.defaultErrorMethod = method;
		return this;
	}




	cancel(message?: string)
	{
		this.cancelTockenSource.cancel(message);
	}




	appendTo(fetchers: Array<Fetcher<any>>)
	{
		this.fetchersToAppendTo = fetchers;
		return this;
	}




	/**
	 *
	 * @param age -1 means always read from cache if it is available
	 */
	readFromCacheIfYoungerThan(age: number = -1)
	{
		this.readFromCacheAgeLimit = age;
		return this;
	}




	writeToCacheOnSuccess(value: boolean = true)
	{
		this.writeToCache = value;
		return this;
	}



	shouldReadFromCache()
	{
		const canReadFromCache = this.readFromCacheAgeLimit !== 0 && Fetcher.ObjectCache.has(this.url);

		if (canReadFromCache)
		{
			if (this.readFromCacheAgeLimit < 0)
			{
				return true;
			}
			else
			{
				const delta = Date.now() - Fetcher.ObjectCache.get(this.url).timestamp;

				if (delta < this.readFromCacheAgeLimit)
				{
					return true;
				}
			}
		}
		else
		{
			return false;
		}
	}




	async run()
	{
		if (IsDev())
		{
			this.showWarnings();
		}

		if (this.fetchersToAppendTo !== undefined)
		{
			this.fetchersToAppendTo.push(this);
		}

		this.requestConfig.cancelToken = this.cancelTockenSource.token;

		if (!["post", "get", "delete"].includes(this.verb))
		{
			throw new Error("Unknown verb");
		}



		try
		{
			let response: AxiosResponse<RESPONSE_TYPE>;

			if (this.shouldReadFromCache())
			{
				response = Fetcher.ObjectCache.get(this.url).response;
			}
			else
			{
				await Sleep(this.delayAmount);

				if (this.verb === "get")
				{
					response = await Axios.get<RESPONSE_TYPE>(this.url, this.requestConfig);
				}
				else if (this.verb === "post")
				{
					response = await Axios.post<RESPONSE_TYPE>(this.url, this.data, this.requestConfig);
				}
				else if (this.verb === "delete")
				{
					response = await Axios.delete(this.url, this.requestConfig);
				}

				MapDeepWithParseInt(response.data);

				if (this.writeToCache)
				{
					Fetcher.ObjectCache.set(this.url, {response, timestamp: Date.now()});
				}
			}

			if (this.successMethod !== undefined)
			{
				this.successMethod(response);
			}
		}
		catch (error)
		{
			let handledError = false;

			if (error.response)
			{
				if (this.yiiExceptionMethod !== undefined && validate(error.response.data, this.yiiExceptionSchema).valid)
				{
					handledError = true;
					this.yiiExceptionMethod(error.response.data);
				}

				if (this.yiiFieldErrorMethod !== undefined && validate(error.response.data, this.yiiFieldErrorSchema).valid)
				{
					handledError = true;
					this.yiiFieldErrorMethod(error.response.data);
				}
			}

			if (!handledError && this.defaultErrorMethod !== undefined)
			{
				handledError = true;
				this.defaultErrorMethod(error);
			}

			if (!handledError && IsDev())
			{
				console.error(error);
				console.warn("Unhandled error");
			}
		}
	}




	showWarnings()
	{
		const warnings = [];

		if (this.fetchersToAppendTo === undefined)
		{
			warnings.push("Unappended fetcher...");
		}

		if (warnings.length > 0)
		{
			console.group(this.url);

			for (const w of warnings)
			{
				console.warn(w);
			}

			console.groupEnd();
		}
	}




	static CancelAll(fetchers: Array<Fetcher<any>>)
	{
		for (const fetcher of fetchers)
		{
			try
			{
				fetcher.cancel();
			}
			catch (error)
			{
				console.info("Error at CancelAll");
			}
		}
	}




	static CreateList(): Array<Fetcher<any>>
	{
		return [];
	}
}
