import * as React from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import IModelCompany from "../../model/IModelCompany";



interface IProps
{
	companyId: number;
	models: IModelCompany[];
	onChange: () => void;
}



export default class DepartmentsList extends React.Component<IProps>
{
	fetchers = Fetcher.CreateList();




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	renderHeader()
	{
		return (
		<Table.Header>
			<Table.Row>
			<Table.HeaderCell>{I18N.DEPARTMENTS}</Table.HeaderCell>
			<Table.HeaderCell></Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		);
	}




	renderBody()
	{
		const {models} = this.props;

		return (
		<Table.Body>
		{
			models.map((model) => (
			<Table.Row key={model.id}>
				<Table.Cell>{model.name}</Table.Cell>
				<Table.Cell textAlign="right">
					<Button.Group labeled icon size="mini">
						<Button color="grey"
							onClick={() => window.location.hash = `#company-edit_${model.id}`}
						>
							<Icon name="edit"/> {I18N.EDIT}
						</Button>

						<Button color="grey"
							onClick = {() => this.deleteModel(model)}
						>
							<Icon name="trash alternate"/> {I18N.DELETE}
						</Button>
					</Button.Group>
				</Table.Cell>
			</Table.Row>))
		}
		</Table.Body>);
	}




	renderFooter()
	{
		return (
		<Table.Footer fullWidth>
			<Table.Row>
				<Table.HeaderCell colSpan="2" textAlign="right">
					<Button floated="right" icon labelPosition="left" primary size="mini"
						onClick={() => window.location.hash = `#company-edit_${this.props.companyId}_`}
					>
						<Icon name="plus" />{I18N.ADD_NEW}
					</Button>
				</Table.HeaderCell>
			</Table.Row>
		</Table.Footer>
		);
	}




	render()
	{
		return (
		<div style = {{marginTop: "50px"}}>
			<Table compact>
				{ this.renderHeader() }
				{ this.renderBody() }
				{ this.renderFooter() }
			</Table>
		</div>);
	}



	deleteModel(model: IModelCompany)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({deletingId: model.id as number});

			new Fetcher({"r": "active/company/delete", "id": model.id})
			.delete()
			.delay(500)
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.setState({deletingId: -1});
				this.props.onChange();
			})
			.run();
		}
	}
}
