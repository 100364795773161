import * as React from "react";
import { DropdownItemProps, Form, SelectProps } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import IModelCompany from "../../model/IModelCompany";




interface IState
{
	options: DropdownItemProps[];
	value: string;
}




export default class CompanySelector extends React.Component<Partial<SelectProps>, IState>
{
	state =
	{
		options: undefined as DropdownItemProps[],
		value: this.props.defaultValue as any,
	};



	fetchers = Fetcher.CreateList();



	componentDidMount(): void
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	async fetchData()
	{
		this.setState({options: undefined});

		new Fetcher<IModelCompany[]>({
			"r": "active/company/index",
			"filter[parent_id]": null,
			"expand": "companies",
		})
		.get()
		.writeToCacheOnSuccess(true)
		.readFromCacheIfYoungerThan(5 * 1000 * 60) // 5 minutes
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			const options: DropdownItemProps[] = [];

			for (const company0 of data)
			{
				options.push({key: company0.id, value: company0.id, text: company0.name});

				for (const company1 of company0.companies)
					options.push({key: company1.id, value: company1.id, text: " ─ " + company1.name});
			}

			this.setState({options});
		})
		.run();
	}




	render()
	{
		const {options} = this.state;

		if (options === undefined)
		{
			return <Form.Input loading disabled value = {I18N.PLEASE_WAIT}/>;
		}
		else
		{
			return <Form.Field>
				<input
					type="hidden"
					name={this.props.name}
					value= { (this.state.value === undefined || this.state.value === null) ? "" : this.state.value.toString() }
				/>

				<Form.Select
					{...this.props}
					onChange = { (event, data) => this.setState({value: data.value.toString()}) }
					options= { options }
				/>
			</Form.Field>;
		}
	}
}
