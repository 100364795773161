import _ from "lodash";
import * as React from "react";
import { Form, Label } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import IModelCapacity from "../../model/IModelCapacity";
import IModelCompany from "../../model/IModelCompany";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";




interface IProps
{
	model: Partial<IModelCapacity>;
	errors: IModelYiiFieldError[];
	disabled: boolean;
}




interface IState
{
	model: IModelCompany;
	errors: IModelYiiFieldError[];
	company_id: number;
	service_id: number;
}




export default class CapacityEditServiceSelector extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();

	state = {
		model: undefined as IModelCompany,
		errors: [] as IModelYiiFieldError[],
		company_id: this.props.model.company_id || User.Instance.company_id,
		service_id: this.props.model.service_id,
	};




	componentDidMount(): void
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchData()
	{
		this.setState({model: undefined});

		new Fetcher<IModelCompany>({
			"r": "active/company/view",
			"expand": [
				"companyServices.service",
				"companies.companyServices.service",
			].join(","),
			"id": User.Instance.company_id,
		})
		.get()
		.writeToCacheOnSuccess(true)
		.readFromCacheIfYoungerThan(5 * 1000 * 60) // 5 minutes
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({model: data});
		})
		.run();
	}




	render()
	{
		const {model} = this.state;
		const {disabled} = this.props;

		if (model === undefined) return <></>;

		const company = model.companies.length > 0 ?
						_.find(model.companies, (c) => c.id === this.state.company_id) :
						model;

		return <>
		{
			this.state.company_id &&
			<input name = "company_id" value = {this.state.company_id} type = "hidden"/>
		}

		{
			this.state.service_id &&
			<input name = "service_id" value = {this.state.service_id} type = "hidden"/>
		}

		{
			model.companies.length > 0
			&&
			<Form.Field disabled = {disabled}>
				<label>
					{I18N.DEPARTMENTS}
				</label>
				<Form.Select
					onChange = { (event, data) => this.setState({company_id: data.value as number}) }
					options= { model.companies.map( (c) => ({"value": c.id, "text": c.name}) ) }
					defaultValue = {this.props.model.company_id}
				/>
			</Form.Field>
		}

		{
			company !== undefined
			&&
			<Form.Field disabled = {disabled}>
				<label>
					{I18N.SERVICE}
				</label>
				<Form.Select
					onChange = { (event, data) => this.setState({service_id: data.value as number}) }
					options= {
						company.companyServices.map(
							(cs) => ({"value": cs.service.id, "text": cs.service.name}),
						)
					}
					defaultValue = {this.props.model.service_id}
				/>
			</Form.Field>
		}

		{
			this.hasError()
			&&
			<Label basic color="red" pointing>
				{
					(this.props.errors as Array<{field: string, message: string}>).map((error, id) =>
					{
						return error.field === "service_id" && <p key={id}> {error.message} </p>;
					})
				}
			</Label>
		}
		</>;
	}



	hasError()
	{
		for (const key in this.props.errors)
			if (this.props.errors[key].field === "service_id")
				return true;

		return false;
	}
}
