import { useState } from "react";
import * as React from "react";
import { Form, Select } from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";




export default function EmailCapacitySelector(props: any)
{
	const [selected, setSelected] = useState("defaultValue" in props ? props.defaultValue : 0xFF);

	return (
	<Form.Field>
		<input type="hidden" name={props.name} value={selected} />
		<Select
			{...props}

			onChange = { (e, {value}) => setSelected(value as number) }

			options={[
				{text: I18N.ALL_CAPACITY_REQUESTS,  value: 0xff},
				{text: I18N.ONLY_IN_MY_REGION,  value: 1 << 0},
				{text: I18N.NONE, value: 0},
			]}
		/>
	</Form.Field>);
}
