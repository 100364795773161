import IsDev from "./IsDev";

export default function QueryUrl(query: any)
{
    const str = [];

    for (const key in query)
    {
        if (query.hasOwnProperty(key))
        {
            if ( Array.isArray(query[key]) )
            {
                for (const value of query)
                {
                    str.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`);
                }
            }
            else
            {
                if (query[key] === null)
                {
                    str.push(encodeURIComponent(key));
                }
                else
                {
                    str.push(`${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`);
                }
            }
        }
    }

    const q = "?" + str.join("&");

    if (IsDev())
        return "http://localhost:8080/" + q;
    else
        return window.location.origin + "/" + q;
}
