import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
// Import css files
import "slick-carousel/slick/slick.css";
import Fetcher from "../../common/Fetcher";
import QueryUrl from "../../common/QueryUrl";
import IModelFile from "../../model/IModelFile";
import "./Style.scss";




interface IState
{
	index: number;
	models: IModelFile[];
}




export default class Advertisement extends React.Component<{}, IState>
{
	state =
	{
		models: [] as IModelFile[],
		index: 0,
	};




	fetchers = Fetcher.CreateList();




	componentDidMount()
	{
		this.fetchModels();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchModels()
	{
		new Fetcher<IModelFile[]>({ r: "node/advertisement" })
		.get()
		.appendTo(this.fetchers)
		.writeToCacheOnSuccess(true)
		.readFromCacheIfYoungerThan(1 * 60 * 1000) // 1 minute
		.onSuccess( ({ data }) => this.setState({models: data}) )
		.run();
	}




	navigate(link: any)
	{
		if (link)
			window.open(link, "_blank");
	}




	render()
	{
		const {models} = this.state;

		if (models.length === 0)
		{
			return <React.Fragment/>;
		}
		else
		{
			const settings = {
				dots: false,
				arrows: true,
				infinite: true,
				autoplaySpeed: 5000,
				autoplay: true,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				pauseOnHover: false,
			};
			return (
				<Slider {...settings}>
					{models.map((model) =>
				(
					<div key={model.id} >
						<p onClick={() => this.navigate(model.link_to)} className="adcaption">
							{model.description}
						</p>
						<div style={
						{
							padding: "56.25% 0 0 0",
							backgroundColor: "#0000008f",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat" ,
							backgroundSize: "contain" ,
							backgroundImage: `url("${QueryUrl({ r: "node/file-download", id: model.id }) + ".jpg"}")`,
						}}>
						</div>
						<p onClick={() => this.navigate(model.link_to)} className="adtitle">
							{model.title}
						</p>
					</div>
				))}
				</Slider>
			);
		}
	}
}
