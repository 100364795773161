import * as React from "react";
import I18N from "../../common/i18n/I18N";
import IApplicationProps from "../application-collection/IApplicationProps";




export default class ChangeLanguage extends React.Component<IApplicationProps>
{
	componentDidMount()
	{
		I18N.ChangeTo(this.props.optionsString);
		window.location.hash = "#launcher";
	}




	render()
	{

		return (
		<React.Fragment>
		</React.Fragment>
		);
	}
}
