import _ from "lodash";
import * as React from "react";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import IModelRegistrationRequest from "../../model/IModelRegistrationRequest";
import IApplicationProps from "../application-collection/IApplicationProps";
import FormViewUi from "../ui-element/FormViewUi";
import PreWrapText from "../ui-element/PreWrapText";




interface IState
{
	models: IModelRegistrationRequest[];
	phase: PhaseFlags;
}




export default class RequestRegistrationList extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		models: [] as IModelRegistrationRequest[],
		phase: PhaseFlags.NONE,
	};




	componentDidMount()
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchData()
	{
		this.setState({phase: PhaseFlags.FETCHING, models: []});

		new Fetcher<IModelRegistrationRequest[]>(
		{
			"r": "node/request-registration-list/models",
			"expand": ["company", "emailExists"].join(","),
		})
		.get()
		.appendTo(this.fetchers)
		.delay(500)
		.onSuccess(({data}) =>
		{
			this.setState({phase: PhaseFlags.NONE, models: data});
		})
		.run();
	}




	render()
	{
		return (
		<div className="wrapper">
		{
			this.state.phase === PhaseFlags.FETCHING
			?
			FormViewUi.Waiting()
			:
			(
				this.state.models.length === 0
				?
				<Segment>{I18N.THERE_ARE_CURRENTLY_NO_REGISTRATION_REQUESTS}</Segment>
				:
				this.state.models.map(this.renderModel.bind(this))
			)
		}
		</div>);
	}


	renderModel(model: IModelRegistrationRequest, index: number)
	{
		return (
			<Segment.Group key = {model.id}>
				<Segment secondary = {model.status === 1}>
					<Header>
						{
							model.company ?
							I18N.ADD_A_NEW_USER_TO + ": " +  model.company.name :
							I18N.CREATE_A_NEW_COMPANY + ": " + model.company_name
						}

						<Header.Subheader>
							<Icon name = "mail"/> { model.email }
						</Header.Subheader>
					</Header>

					<PreWrapText content = {model.description} />
				</Segment>

				{
					model.emailExists &&
					<Segment>
						<Segment color = "red" inverted>
							{ I18N.THIS_EMAIL_HAS_BEEN_USED_ALREADY }
						</Segment>
					</Segment>
				}

				<Segment clearing secondary = {model.status === 1}>
					<Button
						color="green"
						onClick = {() => this.approveModel(model.id)}
						loading = {this.state.phase !== PhaseFlags.NONE}
						disabled = {model.status === 1 || this.state.phase !== PhaseFlags.NONE || model.emailExists}
						floated = "right"
					>
						{model.status === 1 ? I18N.APPROVED : I18N.APPROVE}
					</Button>

					<Button
						color="red"
						onClick = {() => this.deleteModel(model.id)}
						loading = {this.state.phase !== PhaseFlags.NONE}
						disabled = {this.state.phase !== PhaseFlags.NONE}
						floated = "right"
					>
						{I18N.DELETE}
					</Button>
				</Segment>
		</Segment.Group>);
	}




	deleteModel(id: number)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({phase: PhaseFlags.SAVING});

			new Fetcher({"r": "active/registration-request/delete", id})
			.delete()
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				const {models} = this.state;
				_.remove(models, (model) => model.id === id);

				this.setState({models, phase: PhaseFlags.NONE});
			})
			.run();
		}
	}




	approveModel(id: number)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({phase: PhaseFlags.SAVING});

			new Fetcher({"r": "node/request-registration-list/approve", id})
			.get()
			.appendTo(this.fetchers)
			.onSuccess(({data}) =>
			{
				if (data !== null)
				{
					const {models} = this.state;
					_.remove(models, (model) => model.id === id);
					this.setState({models});
				}

				this.setState({phase: PhaseFlags.NONE});
			})
			.run();
		}
	}
}
