import * as React from "react";




interface IProps
{
	repeat?: number;
}




export default class Emsp extends React.Component<IProps>
{
	render()
	{
		const __html = "&emsp;".repeat(this.props.repeat === undefined ? 1 : this.props.repeat);
		return <span dangerouslySetInnerHTML={{__html}}></span>;
	}
}
