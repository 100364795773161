import Axios from "axios";
import IModelUserAuth from "../model/IModelUserAuth";
import MakeAvailableInWindow from "./MakeAvailableInWindow";




export default class User
{
	static Instance: User = new User();
	static Log: any;

	id = -1;
	company_id = -1;
	company_type = -1;
	role = -1;




	static HasSessionKey()
	{
		return User.GetSessionKey() != null;
	}




	static GetSessionKey()
	{
		return sessionStorage.getItem("key");
	}



	static SetSessionKey(key: string)
	{
		sessionStorage.setItem("key", key);
	}



	static RemoveSessionKey()
	{
		sessionStorage.clear();
	}




	static Login(data: IModelUserAuth)
	{
		return User.Instance.login(data);
	}




	static Logout()
	{
		return User.Instance.logout();
	}




	static get IsGuest()
	{
		return User.Instance.isGuest;
	}




	static get IsUser()
	{
		return User.Instance.role === 0;
	}



	static get IsAdmin()
	{
		return User.Instance.role === 9;
	}



	static get IsGod()
	{
		return User.Instance.role === 10;
	}




	login(data: IModelUserAuth)
	{
		// console.log(data);

		User.SetSessionKey(data.key);

		Axios.defaults.headers.common.Authorization = `Bearer ${data.key}`;

		this.role = data.role;
		this.id = data.id;
		this.company_id = data.company_id;
		this.company_type = data.company_type;

		// this.prettyPrint();
	}




	logout()
	{
		User.RemoveSessionKey();

		delete Axios.defaults.headers.common.Authorization;

		this.role = -1;
		this.id = -1;
		this.company_id = -1;
	}



	get isGuest()
	{
		return	!("Authorization" in Axios.defaults.headers.common)
				|| (Axios.defaults.headers.common.Authorization === "")
				|| this.id === -1
				|| this.company_id === -1
				|| this.role === -1;
	}




	private constructor()
	{
		MakeAvailableInWindow("User", User);
	}




	prettyPrint()
	{
		console.table(this);
	}
}
