import _ from "lodash";
import * as React from "react";
import { toast } from "react-toastify";
import { Button, ButtonProps, Header, Icon, Table } from "semantic-ui-react";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import IModelUser from "../../model/IModelUser";
import IApplicationProps from "../application-collection/IApplicationProps";
import FormViewUi from "../ui-element/FormViewUi";
import PreWrapText from "../ui-element/PreWrapText";
import "./Style.scss";




interface IState
{
	isWaiting: boolean;
	models: IModelUser[];
	deletingId: number;
	column: string;
	direction: string;
}




export default class UserList extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		isWaiting: false,
		models: [] as IModelUser[],

		deletingId: -1,

		column: null,
		direction: null,
	};




	componentDidMount()
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchData()
	{
		this.setState({isWaiting: true, models: []});

		new Fetcher<IModelUser[]>({
			"r": "active/user/index",
			"expand": ["canUpdate", "canDelete", "company", "company.parent"].join(","),
		})
		.appendTo(this.fetchers)
		.get()
		.delay(500)
		.onSuccess(({data}) =>
		{
			this.setState({isWaiting: false, models: data});
		})
		.run();
	}




	renderModifyButtons(model: IModelUser)
	{
		if (model.canUpdate || model.canDelete)
		{
			const d = this.state.deletingId;

			const props: ButtonProps =
			{
				icon: true,
				disabled: d !== -1,
				loading:  d !== -1 && model.id === d,
			};

			return (
			<Button.Group
				icon
				size="mini"
			>
				{
					model.canUpdate
					&&
					<Button {...props}
						color="green"
						onClick={() => window.location.hash = `#user-edit_${model.id}`}
					>
						<Icon name="edit"/>
					</Button>
				}
				{
					model.canDelete
					&&
					<Button {...props}
						color="red"
						onClick = {() => this.deleteModel(model)}
					>
						<Icon name="trash alternate"/>
					</Button>
				}
			</Button.Group>);
		}

		return undefined;
	}




	deleteModel(model: IModelUser)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({deletingId: model.id});

			new Fetcher({"r": "active/user/delete", "id": model.id})
			.delete()
			.delay(500)
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				const {models} = this.state;
				_.remove(models, (target) => target.id === model.id);

				this.setState({models, deletingId: -1});
				toast.info(I18N.DELETED);
			})
			.onError(() =>
			{
				toast.error(I18N.AN_UNEXPECTED_ERROR_HAS_HAPPENED_PLEASE_REFRESH_THIS_PAGE_AN__71);
				this.setState({deletingId: -1});
			})
			.run();
		}
	}




	handleSort(clickedColumn: string)
	{
		const { models, column, direction } = this.state;

		if (column !== clickedColumn)
		{
			this.setState(
			{
				column: clickedColumn,
				models: _.sortBy(models, [clickedColumn]),
				direction: "ascending",
			});

			return;
		}

		this.setState(
		{
			models: models.reverse(),
			direction: direction === "ascending" ? "descending" : "ascending",
		});
	}



	renderHeaderCell(title: string, column: string, width: SemanticWIDTHS)
	{
		return (
		<Table.HeaderCell
			width = {width}
			sorted={ this.state.column === column ? this.state.direction : undefined }
			onClick={() => this.handleSort(column)}
		>
			{title}
		</Table.HeaderCell>);
	}




	render()
	{
		return (
		<div className="user-list-container">

		{
			this.state.isWaiting
			?
			FormViewUi.Waiting()
			:
			<React.Fragment>
				<Header as="h3">{I18N.USERS}</Header>

				<Table sortable celled size="small" fixed>
					<Table.Header>
						<Table.Row>
							{ this.renderHeaderCell(I18N.NAME, "nickname", 3) }
							{ this.renderHeaderCell(I18N.ROLE, "role", 3) }
							{ this.renderHeaderCell(I18N.EMAIL, "email", 3) }
							{ this.renderHeaderCell(I18N.COMPANY, "company.name", 5) }
							{ this.renderHeaderCell(I18N.CONTACT, "contact", 5) }
							<Table.HeaderCell width = {2}>{I18N.ACTION}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{ this.state.models.map((model) => {
							const { id, nickname, email, company, contact, role} = model;
							const roleName = {0: "User", 9: "Admin", 10: "System Admin"}[role];
							const companyName = company ? company.name : I18N.EMPTY;
							const parentName = company && company.parent ? `${company.parent.name} / ` : '';

							return (
							<Table.Row key={id}>
								<Table.Cell>{nickname}</Table.Cell>
								<Table.Cell>{roleName}</Table.Cell>
								<Table.Cell>{email}</Table.Cell>
								<Table.Cell>{parentName + companyName}</Table.Cell>
								<Table.Cell><PreWrapText content = {contact} /></Table.Cell>
								<Table.Cell>{ this.renderModifyButtons(model) }</Table.Cell>
							</Table.Row>);
						})}
					</Table.Body>

					{
						(User.IsAdmin || User.IsGod)
						&&
						<Table.Footer fullWidth>
							<Table.Row>
								<Table.HeaderCell colSpan="6">
									<Button
										floated="right" icon labelPosition="left"
										primary size="small"
										as = "a" href = "#user-edit"
									>
										<Icon name="user" /> {I18N.ADD_NEW}
									</Button>

								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					}
				</Table>
			</React.Fragment>
		}
		</div>);
	}
}
