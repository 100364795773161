import moment from "moment";
import * as React from "react";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import IModelCapacityCompany from "../../model/IModelCapacityCompany";
import IModelCompany from "../../model/IModelCompany";
import IModelUser from "../../model/IModelUser";
import Emsp from "../ui-element/Emsp";
import Spacer from "../ui-element/Spacer";
import DecodeYW from "./DecodeYW";




interface IProps
{
	model?: Partial<IModelCapacityCompany>;
	onSuccess: () => void;
}




interface IState
{
	phase: PhaseFlags;
}




export default class CapacityCompanyEdit extends React.Component<IProps>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		phase: PhaseFlags.NONE,
	};




	updateStatus(newStatus: any)
	{
		this.setState({phase: PhaseFlags.SAVING});

		const query =
		{
			"r": "node/capacity-calendar/update-capacity-company-status",
			"id": this.props.model.id,
			"status": newStatus,
		};

		new Fetcher<IModelCapacityCompany>(query)
		.get()
		.appendTo(this.fetchers)
		.delay(500)
		.onSuccess(() =>
		{
			this.setState({phase: PhaseFlags.NONE});
			this.props.onSuccess();
		})
		.run();
	}



	deleteModel()
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({phase: PhaseFlags.SAVING});

			new Fetcher({"r": "active/capacity-company/delete", "id": this.props.model.id})
			.delete()
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.setState({phase: PhaseFlags.NONE});
				this.props.onSuccess();
			})
			.run();
		}
	}



	renderTimeSpan(start_year_week: number, end_year_week: number)
	{
		const arr = [];

		const format = "Do MMM YYYY [(kw] W [)]";

		{
			const {year, week} = DecodeYW(start_year_week);
			arr.push(moment().year(year).isoWeek(week).weekday(0).format(format));
		}
		{
			const {year, week} = DecodeYW(end_year_week);
			arr.push(moment().year(year).isoWeek(week).weekday(6).format(format));
		}

		return arr.join(" - ");
	}




	render()
	{
		const {model} = this.props;
		const capacity = model.capacity;

		return (
		<Segment.Group>
			{
				model.status === 1 &&
				<Segment>
					{ this.renderCompany(capacity.company) }
					{ this.renderUser(capacity.user) }
				</Segment>
			}

			<Segment>
				<Header as="h3">
					<b>{I18N.SERVICE}:</b> {capacity.service.name}

					<Header.Subheader>
						<Icon name="clock"/>{ this.renderTimeSpan(capacity.start_year_week, capacity.end_year_week) }

						{
							capacity.location &&
							<>
								<br/><Icon name="map marker alternate"/>{capacity.location.title}<Emsp/>
							</>
						}
					</Header.Subheader>
				</Header>
				
				{
					capacity.public_comment ?
					<div style={{color: 'black'}}><strong>{I18N.PUBLIC_COMMENT}:</strong> {capacity.public_comment}</div>
					:
					<Spacer/>
				}
				
			</Segment>

			<Segment secondary clearing>
			{
				model.status !== 1
				&&
				<Button
					color="green"
					onClick = {() => this.updateStatus(1)}
					loading = {this.state.phase !== PhaseFlags.NONE}
					disabled = {this.state.phase !== PhaseFlags.NONE}
					floated = "right"
				>
					{I18N.APPROVE}
				</Button>
			}

			{
				model.status !== 2
				&&
				<Button
					color="orange"
					onClick = {() => this.updateStatus(2)}
					loading = {this.state.phase !== PhaseFlags.NONE}
					disabled = {this.state.phase !== PhaseFlags.NONE}
					floated = "right"
				>
					{I18N.REJECT}
				</Button>
			}

			{
				model.status === 2
				&&
				<Button
					color="red"
					onClick = {this.deleteModel.bind(this)}
					loading = {this.state.phase !== PhaseFlags.NONE}
					disabled = {this.state.phase !== PhaseFlags.NONE}
					floated = "right"
				>
					{I18N.DELETE}
				</Button>
			}
			</Segment>
		</Segment.Group>);
	}




	renderUser(user: IModelUser)
	{
		if (user)
		{
			return (
			<Header as = "h4">
				<Icon name = "user"/>
				<Header.Content>
					{user.nickname || user.email}
					<Header.Subheader>
						{ user.email }

						{
							user.phone &&
							<Icon name= "phone volume" />
						}

						{ user.phone }
					</Header.Subheader>
				</Header.Content>
			</Header>);
		}

	}




	renderCompany(company: IModelCompany)
	{
		const names = [] as string[];
		let id = company.id;

		if (company.parent)
		{
			names.push(company.parent.name);
			id = company.parent.id;
		}

		names.push(company.name);

		return (
		<Header as = "h3">
			<a href = { "#company_" + id }> { names.join(" / ") } </a>
		</Header>);
	}
}
