import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, Image, Message, Placeholder} from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import QueryUrl from "../../common/QueryUrl";
import IModelCompany from "../../model/IModelCompany";
import IModelFile from "../../model/IModelFile";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";
import FieldForModel from "../for-model/FieldForModel";
import FormViewUi from "../ui-element/FormViewUi";




type Phases = "invalid_id" | "fetching" | "saving" | "editor";




interface IState
{
	model: IModelFile;
	errors: IModelYiiFieldError[];
	phase: Phases;
}




export default class FileEdit extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		model: undefined as IModelFile,
		errors: [],
		phase: "fetching" as Phases,
	};




	componentDidMount()
	{
		const id = parseInt(this.props.optionsString);

		if (isNaN(id)) // Creating
		{
			this.setState({phase: "invalid_id"});
		}
		else
		{
			this.fetchModel(id);
		}
	}




	fetchModel(id: number)
	{
		this.setState({model: undefined, phase: "fetching"});

		new Fetcher({
			"r": "active/file/view",
			id,
			"expand": ["company"].join(","),
		})
		.get()
		.appendTo(this.fetchers)
		.delay(500)
		.onSuccess(({data}) =>
		{
			this.setState({model: data as any, phase: "editor"});
		})
		.run();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	async formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
		const formData = new FormData(formEvent.target as HTMLFormElement);

		const query: any = {"r": "active/file/update", "id": this.state.model.id};

		this.setState({phase: "saving"});

		new Fetcher<IModelFile>(query)
		.post(formData)
		.appendTo(this.fetchers)
		.delay(500)
		.onSuccess(() =>
		{
			this.setState({errors: [], phase: "editor"});
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors, phase: "editor"});
		})
		.run();
	}



	renderForm()
	{
		const {model, phase} = this.state;

		const hasCompany = model.company !== null && model.company !== undefined;
		const isLogoFile = hasCompany && model.id === model.company.logo_file_id;
		const isCoverFile = hasCompany && model.id === model.company.cover_file_id;
		const isAdFile = hasCompany && model.id === model.company.advertisement_file_id;

		const buttonsState = {
			loading: (phase === "saving"),
		};

		return (
		<Form onSubmit={this.formOnSubmit.bind(this)} className="wrapper">

			{FormViewUi.BackButton(I18N.PHOTOS, "file-list")}

			<br/><br/>

			<Image src = {QueryUrl({"r": "node/file-download/index", "id": model.id})} />

			<br/><br/>

			<div>
				<b>{I18N.COMPANY}:</b> {hasCompany ? model.company.name : "No Company!"}<br/><br/>
			</div>

			<FieldForModel<IModelFile> {...this.state}
				field = "title"
				label = {I18N.TITLE}
				disabled = {phase !== "editor"}
				control = {<Form.Input size="huge" placeholder = {I18N.TITLE} />}
			/>

			<FieldForModel<IModelFile> {...this.state}
				field = "link_to"
				label = {I18N.URL}
				disabled = {phase !== "editor"}
				control = {<Form.Input  placeholder = {I18N.URL} />}
			/>

			<FieldForModel<IModelFile> {...this.state}
				field = "description"
				label = {I18N.DESCRIBE_IT + "..."}
				disabled = {phase !== "editor"}
				control =
				{<TextareaAutosize
					minRows = {2}
					rows = {2}
					placeholder = {I18N.DESCRIBE_IT}
				/>}
			/>


			<Button {...buttonsState}
				primary
				type="submit"
				disabled = {(phase === "saving")}
			>
				{I18N.SAVE}
			</Button>


			{
				!!model.company_id &&
				<Button.Group floated="right" vertical>
					<Button {...buttonsState}
						type="reset"
						size="mini"
						disabled = {(phase === "saving" || model.company_id === null || isLogoFile)}
						onClick={() => this.setCompanyImage("logo_file_id", model.id)}
					>
						{isLogoFile ? I18N.THIS_IMAGE_IS_COMPANYS_LOGO : I18N.SET_AS_THE_COMPANYS_LOGO}
					</Button>

					<Button {...buttonsState}
						type="reset"
						size="mini"
						disabled = {(phase === "saving" || model.company_id === null || isCoverFile)}
						onClick={() => this.setCompanyImage("cover_file_id", model.id)}
					>
						{isCoverFile ? I18N.THIS_IMAGE_IS_COMPANYS_COVER_IMAGE : I18N.SET_AS_THE_COMPANYS_COVER_IMAGE}
					</Button>

					<Button {...buttonsState}
						type="reset"
						size="mini"
						negative={isAdFile}
						disabled = {(phase === "saving" || model.company_id === null)}
						onClick={
							() => this.setCompanyImage("advertisement_file_id", isAdFile ? null : model.id)
						}
					>
						{isAdFile ? I18N.UNSET_AS_THE_COMPANYS_ADVERTISEMENT_IMAGE : I18N.SET_AS_THE_COMPANYS_ADVERTISEMENT_IMAGE}
					</Button>
			</Button.Group>
			}


		</Form>
		);
	}




	setCompanyImage(field: string, fileId: number | null)// {id:number, company_id:number})
	{
		const formData = new FormData();

		formData.append(field, fileId === null ? "" : fileId.toString());

		this.setState({phase: "saving"});

		new Fetcher<IModelCompany>({"r": "active/company/update", "id": this.state.model.company_id})
		.post(formData)
		.appendTo(this.fetchers)
		.delay(500)
		.onSuccess(({data}) =>
		{
			const model = this.state.model;
			model.company = data;
			this.setState({phase: "editor", model});
		})
		.run();
	}




	render()
	{
		const {phase} = this.state;

		return (
		<React.Fragment>
			{
				["saving" , "editor"].includes(phase)
				&&
				this.renderForm()
			}

			{
				phase === "fetching"
				&&
				this.renderFetching()
			}

			{
				phase === "invalid_id"
				&&
				<Message
					error
					header="The requested file id is wrong!"
					list={[
					"Please avoid entering URL in location bar",
					"Please go to home page and try to do this operation through displayed menus",
					"Please report this problem to developers...",
					]}
				/>
			}
		</React.Fragment>);
	}




	renderFetching(): React.ReactNode
	{
		return (
		<div className="wrapper">
			<Placeholder fluid>
				<Placeholder.Header>
					<Placeholder.Image rectangular />
					<Placeholder.Line />
					<Placeholder.Line />
				</Placeholder.Header>

				<Placeholder.Paragraph>
					<Placeholder.Line />
					<Placeholder.Line />
				</Placeholder.Paragraph>
			</Placeholder>
		</div>);
	}
}
