import Axios from "axios";
import moment from "moment";
import * as React from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import { Message } from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";
import IsDev from "../../common/IsDev";
import LocationHash from "../../common/LocationHash";
import MakeAvailableInWindow from "../../common/MakeAvailableInWindow";
import MapDeepWithParseInt from "../../common/MapDeepWithParseInt";
import User from "../../common/User";
import CapacityCalendar from "../capacity-calendar/CapacityCalendar";
import CapacityCRUD from "../capacity-crud/CapacityCRUD";
import ChangeLanguage from "../change-language/ChangeLanguage";
import CompanyEdit from "../company-edit/CompanyEdit";
import CompanyList from "../company-list/CompanyList";
import CompanyView from "../company-view/CompanyView";
import FileEdit from "../file-edit/FileEdit";
import FileList from "../file-list/FileList";
import FileUpload from "../file-upload/FileUpload";
import Launcher from "../launcher/Launcher";
import LocationCRUD from "../location_crud/LocationCRUD";
import LoginWithToken from "../login-with-token/LoginWithToken";
import Login from "../login/Login";
import Logout from "../logout/Logout";
import MakeCapacityRequest from "../make-capacity-request/MakeCapacityRequest";
import RequestRegistrationList from "../request-registration-list/RequestRegistrationList";
import RequestRegistration from "../request-registration/RequestRegistration";
import ResetPassword from "../reset-password/ResetPassword";
import ServiceCRUD from "../service-crud/ServiceCRUD";
import PasswordUpdate from "../user-edit/PasswordUpdate";
import UserEdit from "../user-edit/UserEdit";
import UserList from "../user-list/UserList";
import NavigationBar from "./../navigation-bar/NavigationBar";
import "./Style.scss";




export default class ApplicationCollection extends React.Component
{
	state =
	{
		opacity: 0,
		applicationUri: undefined,
		optionsString: undefined,
	};



	private futureState = {
		applicationUri: undefined,
		optionsString: undefined,
	};

	runToFutureIsRunning: boolean = false;
	private lastAnimationTimeStamp: number = -1;

	private wideNavigationBar = false;




	constructor(props: Readonly<{}>)
	{
		super(props);

		MakeAvailableInWindow("Axios", Axios);
		MakeAvailableInWindow("LocationHash", LocationHash);
		MakeAvailableInWindow("moment", moment());
		MakeAvailableInWindow("MapDeepWithParseInt", MapDeepWithParseInt);
		MakeAvailableInWindow("toast", toast);

		I18N.ChangeTo(IsDev() ? "en" : "de");
		Axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

		window.addEventListener("hashchange", this.renderHash.bind(this));
	}




	componentDidMount()
	{
		this.renderHash();
	}




	renderHash()
	{
		const {applicationUri, optionsString} = new LocationHash().extractPart();

		// Pages that are only visible to guest (not logged in) users.
		const noAuthPages = [
			"request-registration" , "login",
			"reset-password", "login-with-token",
			"change-language", "launcher",
			"companies", "company", undefined, // undefined: launcher page
		];

		if (User.IsGuest && !noAuthPages.includes(applicationUri))
		{
			const hash = window.location.hash.split("#")[1];

			if (hash === undefined)
				window.location.hash = `#login`;
			else
				window.location.hash = `#login_${hash}`; // For returning later to requested page.
			return;
		}

		if (applicationUri === undefined)
		{
			window.location.hash = "#launcher";
			return;
		}
		else
		{
			this.futureState = {
				applicationUri,
				optionsString,
			};

			this.invokeRunToTheFuture();
		}
	}




	invokeRunToTheFuture()
	{
		if (!this.runToFutureIsRunning)
		{
			this.runToTheFuture();
		}
	}




	private async runToTheFuture()
	{
		this.runToFutureIsRunning = true;

		let delta = 0.12;

		if (this.lastAnimationTimeStamp === -1)
		{
			this.lastAnimationTimeStamp = Date.now();
		}
		else
		{
			const t  = Date.now();
			delta = (t - this.lastAnimationTimeStamp);
			this.lastAnimationTimeStamp = t;

			delta /= 200;
		}

		if (this.state.applicationUri === undefined)
		{
			// Old application is fully closed so open new application
			this.setState({opacity: 0, ...this.futureState});
		}

		if (
			this.futureState.applicationUri !== this.state.applicationUri
			||
			this.futureState.optionsString !== this.state.optionsString
		)
		{
			// Old Application is fading out
			if (this.state.opacity > 0)
			{
				const opacity = this.state.opacity - delta;
				this.setState({opacity});
			}
			else
			{
				this.setState({opacity: 0, applicationUri: undefined, optionsString: undefined});
			}
		}

		else
		{
			// New application is fading in
			if (this.state.opacity < 1)
			{
				const opacity = this.state.opacity + delta;
				this.setState({opacity});
			}
			else
			{
				this.setState({opacity: 1});
				this.runToFutureIsRunning = false;

				this.lastAnimationTimeStamp = -1;

				return;
			}
		}

		requestAnimationFrame(this.runToTheFuture.bind(this));
	}




	render()
	{
		const _ = (params: {if: string, run: any, wide?: "wide" | "narrow" | "dont-change"}) =>
		{
			if (params.wide === undefined || params.wide === "narrow")
			{
				this.wideNavigationBar = false;
			}
			else if (params.wide === "wide")
			{
				this.wideNavigationBar = true;
			}

			if (this.state.applicationUri === params.if)
				return (
				<params.run
					optionsString={this.state.optionsString}
				/>);
			else
				return false;
		};

		return (
		<>

			<div style={{opacity: this.state.opacity}} className="ApplicationCollection">
			{
				this.state.applicationUri === undefined
				||	// Don't go further if it is true
				_({ if: "launcher" , run: Launcher, wide: "dont-change" })
				||
				_({ if: "request-registration" , run: RequestRegistration })
				||
				_({ if: "request-registration-list" , run: RequestRegistrationList })
				||
				_({ if: "login" , run: Login })
				||
				_({ if: "login-with-token" , run: LoginWithToken })
				||
				_({ if: "logout" , run: Logout })
				||
				_({ if: "services" , run: ServiceCRUD })
				||
				_({ if: "regions" , run: LocationCRUD })
				||
				_({ if: "companies" , run: CompanyList })
				||
				_({ if: "company-edit" , run: CompanyEdit})
				||
				_({ if: "user-edit" , run: UserEdit })
				||
				_({ if: "users" , run: UserList })
				||
				_({ if: "update-password", run: PasswordUpdate })
				||
				_({ if: "reset-password", run: ResetPassword })
				||
				_({ if: "change-language", run: ChangeLanguage })
				||
				_({ if: "file-upload", run: FileUpload })
				||
				_({ if: "file-edit", run: FileEdit })
				||
				_({ if: "file-list", run: FileList })
				||
				_({ if: "capacity-calendar", run: CapacityCalendar, wide: "wide" })
				||
				_({ if: "make-capacity-request", run: MakeCapacityRequest })
				||
				_({ if: "capacity-requests", run: CapacityCRUD })
				||
				_({ if: "company", run: CompanyView })
				||

				<Message
					error
					header="HUH? the was a request for invalid application"
					list={[
					"Please avoid entering URL in location bar",
					"Please go to home page to resolve this problem",
					]}
				/>
			}
			</div>


			<NavigationBar
				visible = { this.state.applicationUri !== "launcher" }
				wide = {this.wideNavigationBar}
			/>

			<ToastContainer
				position="bottom-right"
				autoClose={4000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				draggable
				pauseOnHover
				transition = {Slide}
			/>

		</>
		);
	}
}
