import * as React from "react";
import Fetcher from "../../common/Fetcher";
import QueryUrl from "../../common/QueryUrl";
import IModelFile from "../../model/IModelFile";




interface IState
{
	model: IModelFile;
}




export default class Wallpaper extends React.Component<any, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		model: undefined as IModelFile,
	};




	fetchFile()
	{
		this.setState({model: undefined});

		new Fetcher<IModelFile>({"r": "node/launcher/wallpaper"})
		.get()
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({model: data});
		})
		.run();
	}




	componentDidMount(): void
	{
		this.fetchFile();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	render()
	{
		const {model} = this.state;

		return (
			<div className="Wallpaper" style={{}}>

				{
					this.state.model
					&&
					<img src={QueryUrl({"r": "node/file-download", "id": model.id})} alt="wallpaper"/>
				}

				{
					this.state.model
					&&
					<div className="info">
						<h3>{this.state.model.title}</h3>
						<div>{this.state.model.description}</div>
					</div>
				}

			</div>
		);
	}
}
