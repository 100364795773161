import moment from "moment";

export default function EncodeYW(year: number, week: number, sanitize: boolean = true)
{
	if (sanitize)
	{
		if (week <= 0)
		{
			const prevYearWeeks = moment().year(year - 1).isoWeeksInYear();
			return EncodeYW (year - 1, prevYearWeeks + week);
		}
		else
		{
			const weeksInThisYear = moment().year(year).isoWeeksInYear();

			if (week > weeksInThisYear)
			{
				return EncodeYW (year + 1, week - weeksInThisYear);
			}
		}
	}

	return (year * 100) + week;
}
