import * as React from "react";
import { Dimmer, Icon, List, Loader, Message, Step } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import IModelService from "../../model/IModelService";
import IApplicationProps from "../application-collection/IApplicationProps";
import SingleSectorCRUD from "./SingleServiceCRUD";
import "./Style.scss";




interface IState
{
	phase: PhaseFlags;
	models: IModelService[];
	stepModels: IModelService[];
}




export default class ServiceCRUD extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();

	state =
	{
		phase: PhaseFlags.FETCHING,
		models: [],
		stepModels: undefined as IModelService[],
	};




	componentDidMount()
	{
		this.fetchModels();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	async fetchModels()
	{
		this.setState({phase: PhaseFlags.FETCHING});

		await new Fetcher<IModelService[]>(
		{
			"r": "active/service/index",
			"expand": "childrenCount, depth",
			"filter[parent_id]": this.props.optionsString === undefined ? null : this.props.optionsString,
		})
		.appendTo(this.fetchers)
		.get()
		.delay(500)
		.onSuccess(({data}) =>
		{
			this.setState({models: data, phase: PhaseFlags.NONE});
		})
		.run();

		if (this.state.stepModels === undefined)
		{
			new Fetcher<IModelService[]>(
			{
				"r": "active/service/steps-to",
				"id": this.props.optionsString,
			})
			.appendTo(this.fetchers)
			.get()
			.onSuccess(({data}) =>
			{
				this.setState({stepModels: data});
			})
			.run();
		}
	}




	renderMessage()
	{
		return (
		<Message icon>
			<Icon name="help"/>

			<Message.Content>
				{
					<Message.Header>{I18N.HERE_YOU_CAN_CREATE_OR_EDIT_SERVICES}</Message.Header>
				}

				<List bulleted>
        			<List.Item>{I18N.TO_ADD_A_NEW_SERVICE_FILL_THE_EMPTY_FORM_AT_THE_BOTTOM_OF_TH__67}</List.Item>
        			<List.Item><b>{I18N.BEFORE_EDITING_OR_DELETING_ANY_ENTRY_PLEASE_CONSIDER_THAT_IT__121}</b></List.Item>
        			<List.Item>{I18N.TO_ADD_SUBCATEGORIES_TO_AN_EXISTING_SERVICE_CLICK_ON_THE_SER__72}</List.Item>
      			</List>
			</Message.Content>
		</Message>);
	}




	render()
	{
		const {phase, models} = this.state;
		const p = {disabled: (phase !== PhaseFlags.NONE), parent: this};


		return (
		<Dimmer.Dimmable as={"div"} className="wrapper">

			{ this.renderMessage() }

			{ this.renderSteps() }

			{
				this.state.models.map((model, index) =>
				{
					return (
					<SingleSectorCRUD {...p}
						key = {model.id}
						model = {model}
					/>);
				})
			}

			<SingleSectorCRUD {...p}
				key = {-1 * models.length}
				model = {{parent_id: parseInt(this.props.optionsString)} as any}
			/>

			{
				phase === PhaseFlags.FETCHING
				&&
				<Dimmer active inverted><Loader/></Dimmer>
			}
		</Dimmer.Dimmable>);
	}




	renderSteps()
	{
		const models = this.state.stepModels;

		if (models !== undefined && models.length > 0)
		{
			return (
			<Step.Group  attached="top">
			{
				models.map((model, index) =>
				{
					const p = {
						href: "#services" + (index === 0 ? "" : "_" + models[index - 1].id),
						key: model.id,
					};

					return (
					<Step link {...p}>
						<Step.Content>
							<Step.Title>
								<Icon name="arrow circle up"/>
								{model.name}
							</Step.Title>
						</Step.Content>
					</Step>);
				})
			}
			</Step.Group>);
		}
	}
}
