import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, Icon, Message, Input } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import User from "../../common/User";
import IModelUser from "../../model/IModelUser";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";
import FieldForModel from "../for-model/FieldForModel";
import FormViewUi from "../ui-element/FormViewUi";
import RoleSelector from "../ui-element/RoleSelector";
import Spacer from "../ui-element/Spacer";
import EmailCapacitySelector from "./EmailCapacitySelector";
import UserCompanySelector from "./UserCompanySelector";




interface IState
{
	phase: PhaseFlags;
	model: Partial<IModelUser>;
	errors: IModelYiiFieldError[];
}




export default class UserEdit extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		phase: PhaseFlags.NONE,
		model: undefined as Partial<IModelUser>,
		errors: [],
	};




	componentDidMount()
	{
		const id = parseInt(this.props.optionsString);

		if (isNaN(id)) // Creating
		{
			const model = {id: undefined, company_id: User.Instance.company_id};
			this.setState({model});
		}
		else
		{
			this.fetchModel(id);
		}
	}




	fetchModel(id: number)
	{
		this.setState({
			model: undefined,
			phase: PhaseFlags.FETCHING,
		});

		new Fetcher({"r": "active/user/view", "expand": "company", id})
		.get()
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({
				model: data,
				phase: PhaseFlags.NONE,
			});
		})
		.run();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	async formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
		const formData = new FormData(formEvent.target as HTMLFormElement);

		PrettyPrintFormData(formData);

		const query: any = {"r": "node/user-edit/sign-up-new-user"};

		if (this.state.model.id !== undefined)
		{
			query.r = "active/user/update";
			query.id = this.state.model.id;
		}

		this.setState({phase: PhaseFlags.SAVING});

		new Fetcher<IModelUser>(query)
		.post(formData)
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({
				errors: [],
				phase: PhaseFlags.NONE,
			});

			window.location.hash = "#user-edit_" + data.id;
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({
				errors,
				phase: PhaseFlags.NONE,
			});
		})
		.run();
	}




	render()
	{
		return (
		<React.Fragment>
			{
				this.state.model === undefined
				?
				<div className="wrapper">
					{ FormViewUi.Waiting() }
				</div>
				:
				this.renderForm()
			}
		</React.Fragment>);
	}




	renderForm()
	{
		const {model} = this.state;
		const isCreating = (this.state.model.id === undefined);
		const isSaving = this.state.phase === PhaseFlags.SAVING;

		type T = Partial<IModelUser>;

		return (
		<Form onSubmit={this.formOnSubmit.bind(this)} className="wrapper">

			{FormViewUi.BackButton(I18N.USERS, "users")}

			{this.renderMessage()}

			{
				User.IsGod || User.IsAdmin ?
				
				<FieldForModel<T> {...this.state}
					required
					field = "email"
					label = {I18N.EMAIL}
					control = {<Form.Input size="huge" placeholder = {I18N.UNIQUE_EMAIL_ADDRESS} />}
					disabled = {isSaving}
				/>
				:
				<Form.Field>
					<label>{I18N.EMAIL}</label>
					<Input disabled value={model.email} ></Input>
				</Form.Field>
			}

			

			<FieldForModel<T> {...this.state}
				field="nickname"
				label={I18N.NICKNAME}
				control = {<Form.Input size="huge" placeholder = {I18N.THIS_WILL_BE_PUBLICLY_AVAILABLE} />}
				disabled = { isSaving }
			/>

			<FieldForModel<T> {...this.state}
				field="phone"
				label={I18N.PHONE_NUMBER}
				control = {<Form.Input placeholder = {I18N.THIS_WILL_BE_PUBLICLY_AVAILABLE} />}
				disabled = { isSaving }
			/>

			<FieldForModel<T> {...this.state}
				field= "contact"
				label= { I18N.CONTACT }
				control =
				{<TextareaAutosize
					minRows = {2}
					rows = {2}
					placeholder = {I18N.CONTACT + "..."}
				/>}
				disabled = { isSaving }
			/>

			{
				model !== undefined && model.company !== undefined && model.company.type === 1
				&&
				<>
					<Spacer/>
					<FieldForModel<T> {...this.state}
						field = "email_flags"
						label = {I18N.WHICH_CAPACITY_EMAILS_DO_YOU_WANT_TO_RECEIVE}
						control = {<EmailCapacitySelector placeholder={I18N.WHICH_CAPACITY_EMAILS_DO_YOU_WANT_TO_RECEIVE}/>}
						disabled = { isSaving }
					/>
				</>
			}


			<Spacer/>

			<FieldForModel<T> {...this.state}
				field = "role"
				label = {I18N.ROLE}
				control = {<RoleSelector placeholder={I18N.CHOOSE_A_ROLE_FOR_THIS_USER}/>}
				disabled = { this.state.model.id === User.Instance.id || isSaving }
			/>

			<FieldForModel<T> {...this.state}
				field = "company_id"
				label = {I18N.COMPANY}
				control = {<UserCompanySelector />}
				disabled = {!(User.IsGod || User.IsAdmin) || isSaving}
			/>

			<Spacer/>

			<Button primary type="submit" disabled={ isSaving } loading={ isSaving }>
				{isCreating ? I18N.ADD_NEW : I18N.SAVE}
			</Button>

			{
				this.state.model.id !== undefined
				&&
				<a className="ui secondary button" href={"#update-password_" + this.state.model.id}>
					{I18N.UPDATE_PASSWORD}
				</a>
			}

		</Form>
		);
	}




	renderMessage()
	{
		const isCreating = (this.state.model.id === undefined);

		return (
		<Message icon>
			<Icon name="help"/>

			<Message.Content>
				{
					isCreating
					?
					<Message.Header>{I18N.DO_YOU_WANT_TO_SIGN_UP_A_NEW_USER}?</Message.Header>
					:
					<Message.Header>{I18N.DO_YOU_WANT_TO_EDIT_YOUR_PROFILE}?</Message.Header>
				}
				{I18N.HERE_YOU_CAN_DESCRIBE_ALL_THE_USERS_PROPERTIES}.<br/>
				{I18N.TO_UPDATE_PASSWORD_CLICK_ON_THE_UPDATE_PASSWORD_BUTTON}.
			</Message.Content>
		</Message>);
	}
}
