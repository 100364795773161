import * as React from "react";
import { Dimmer, Icon, Loader, Message } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import IModelUserAuth from "../../model/IModelUserAuth";
import IApplicationProps from "../application-collection/IApplicationProps";




interface IState
{
	phase: "waiting" | "error";
}




export default class LoginWithToken extends  React.Component<IApplicationProps, IState>
{
	state =
	{
		phase: "waiting" as any,
	};




	componentDidMount()
	{
		this.takeLoginStep();
	}




	takeLoginStep()
	{
		if (!User.IsGuest) // If user has already logged in
		{
			window.location.hash = "#update-password";
		}
		else
		{
			this.runTokenLogin();
		}
	}



	private async runTokenLogin()
	{
		this.setState({phase: "waiting"});

		await new Fetcher<IModelUserAuth>({"r": "node/auth/with-reset-password-token", "token": this.props.optionsString})
		.get()
		.delay(500)
		.onSuccess(({data}) =>
		{
			User.Login(data);
			this.takeLoginStep();
		})
		.onError(() =>
		{
			this.setState({phase: "error"});
		})
		.run();
	}




	render()
	{
		const {phase} = this.state;


		return (
			<div className="LoginWithToken wrapper">
				{
					phase === "waiting"
					&&
					<Dimmer active inverted>
						<Loader inverted>{I18N.PLEASE_WAIT}...</Loader>
					</Dimmer>
				}

				{
					phase === "error"
					&&
					<Message icon>
						<Icon name="ban"/>

						<Message.Content>
							<Message.Header>{I18N.INVALID_REQUEST}!</Message.Header>
							<a href="#login">{I18N.LOGIN}</a> / <a href="#login">{I18N.FORGOT_YOUR_PASSWORD}</a>
						</Message.Content>
					</Message>
				}
			</div>
		);
	}
}
