import MakeAvailableInWindow from "./MakeAvailableInWindow";

enum PhaseFlags
{
	NONE			= 0,
	EDITING			= 1 << 0,
	FETCHING		= 1 << 1,
	SAVING			= 1 << 2,
	LOADING_INFO	= 1 << 3,
	ERROR			= 1 << 4,
	SUCCESS			= 1 << 5,
}

MakeAvailableInWindow("PhaseFlags", PhaseFlags);


export default PhaseFlags;
