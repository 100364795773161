import * as React from 'react';
import { Modal } from 'semantic-ui-react';

export default function Videos() {
  return (
    <section style={{ display: 'flex', width: '100%', marginTop: '5px' }} className='links'>
      {/* video 1 */}
      <VideoModal
        trigger={
          <div className='video-trigger'>
            <img style={{ width: '100%', height: '100%' }} src={'./video1.png'}></img>
            <p>Homeoffice im SICH ganz leicht mithilfe dem Chatportal "Slack"</p>
          </div>
        }
        title='Homeoffice im SICH ganz leicht mithilfe dem Chatportal "Slack"'
        src='https://www.youtube.com/embed/xXeBFHxuHFE'
      />

      {/* video 2 */}
      <VideoModal
        trigger={
          <div className='video-trigger'>
            <img style={{ width: '100%', height: '100%' }} src={'./video2.png'}></img>
            <p>Kapazitätspool des Säntis Innovations-Cluster Holz</p>
          </div>
        }
        title='Kapazitätspool des Säntis Innovations-Cluster Holz'
        src='https://www.youtube.com/embed/3balClwz3W4'
      />
    </section>
  );
}

const VideoModal = ({ trigger, title, src }) => (
  <Modal closeIcon trigger={trigger}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Content>
      <div className='embed-container'>
        <iframe src={`${src}?&autoplay=1`} frameBorder='0' allowFullScreen></iframe>
      </div>
    </Modal.Content>
  </Modal>
);
