export default function IsDev(thenReturn?: any)
{
	if (window.location.hostname === "localhost")
	{
		return thenReturn === undefined ? true : thenReturn;
	}
	else
	{
		return thenReturn === undefined ? false : undefined;
	}
}
