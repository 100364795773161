import _ from "lodash";
import * as React from "react";
import { Icon } from "semantic-ui-react";
import MakeDataAttributeObject from "../../common/MakeDataAttributeObject";
import IModelCapacity from "../../model/IModelCapacity";
import IModelCapacityCompany from "../../model/IModelCapacityCompany";
import IModelInOutCapacity from "../../model/IModelInOutCapacity";
import CapacityCalendar from "./CapacityCalendar";




interface IProps
{
	yw: number;
	model: IModelInOutCapacity;
	parentCapacityCalendar: CapacityCalendar;
}




export default class WeekSpan extends React.Component<IProps>
{
	get capacity()
	{
		const {model} = this.props;
		return	model.dir === "in" ?
				(model.data as IModelCapacityCompany).capacity :
				(model.data as IModelCapacity);
	}




	get isStartingCell()
	{
		const {model, yw} = this.props;

		return (model.start_year_week === yw);
	}




	get isFinishingCell()
	{
		const {model, yw} = this.props;

		return (model.end_year_week === yw);
	}




	renderOut()
	{
		const {model} = this.props;

		if (model.dir === "out")
		{
			type T = IModelCapacityCompany[];

			const requestsByType =
			[
				_.filter(this.capacity.capacityCompanies as T, (req) => req.status === 0),
				_.filter(this.capacity.capacityCompanies as T, (req) => req.status === 1),
				_.filter(this.capacity.capacityCompanies as T, (req) => req.status === 2),
			];

			return (
			<div>
			{
				this.isStartingCell
				&&
				<React.Fragment>
					<div className="label">{ requestsByType[1].length }</div>
					<div className="name">{ this.capacity.service.name }</div>
				</React.Fragment>
			}
			</div>);
		}
	}




	renderIn()
	{
		const {model} = this.props;

		if (model.dir === "in")
		{
			type T = IModelCapacityCompany;

			const status = (model.data as T).status;

			return (
			<div>
				{
					this.isStartingCell
					&&
					<React.Fragment>
						<div className="label">
							{status === 0 && <Icon name="time"/>}
							{status === 1 && <Icon name="check"/>}
							{status === 2 && <Icon name="delete"/>}
						</div>
						<div className="name">{ this.capacity.service.name }  </div>
					</React.Fragment>
				}
			</div>);
		}
	}



	getStatus()
	{
		const {model} = this.props;

		if (model.dir === "out")
		{
			type T = IModelCapacityCompany[];

			const requestsByType =
			[
				_.filter(this.capacity.capacityCompanies as T, (req) => req.status === 0).length,
				_.filter(this.capacity.capacityCompanies as T, (req) => req.status === 1).length,
				_.filter(this.capacity.capacityCompanies as T, (req) => req.status === 2).length,
			];

			if (requestsByType[1] > 0)
			{
				return 1;
			}
			else if (requestsByType[2] > 0)
			{
				return 2;
			}
		}
		else if (model.dir === "in")
		{
			type T = IModelCapacityCompany;
			const status = (model.data as T).status;

			return status;
		}

		return 0;
	}




	render()
	{
		const {model, parentCapacityCalendar, yw} = this.props;
		const highlightId = parentCapacityCalendar.state.highlighted;

		if (model.visual_stack === undefined)
		{
			throw new Error("visual_stack has to be set for all models");
		}

		const capacity = this.capacity;

		const style: React.CSSProperties = {top: (model.visual_stack * 25) + "px"};

		const highlighted = (highlightId === parseInt(capacity.id as any)) || (highlightId === -1);

		const isInFilter = (
			(model.dir === "in" && this.capacity.type === 0 && this.props.parentCapacityCalendar.state.filterIn0)
			||
			(model.dir === "in" && this.capacity.type === 1 && this.props.parentCapacityCalendar.state.filterIn1)
			||
			(model.dir === "out" && this.capacity.type === 0 && this.props.parentCapacityCalendar.state.filterOut0)
			||
			(model.dir === "out" && this.capacity.type === 1 && this.props.parentCapacityCalendar.state.filterOut1)
		);

		const serviceFilter = this.props.parentCapacityCalendar.state.serviceFilters.find((sf, index, obj) => {
			const service = model.dir === "out" ?
							(model.data as IModelCapacity).service :
							(model.data as IModelCapacityCompany).capacity.service;

			return service.id === sf.service.id;
		});

		const attr = MakeDataAttributeObject(
		{
			yw,
			highlighted : highlighted && isInFilter,
			is_in_filter: isInFilter && serviceFilter.active,
			model_id: capacity.id,
			status: this.getStatus(),
			start: this.isStartingCell,
			end: this.isFinishingCell,
			type: ["offer", "request"][this.capacity.type],
			dir: model.dir,
		});


		return (
		<div className="WeekSpan" style = {style} {...attr}>
		{
			this.renderOut()
			||
			this.renderIn()
		}
		</div>);
	}
}
