import * as React from "react";
import { toast } from "react-toastify";
import { Button, Form, Icon, Message } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import User from "../../common/User";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";
import FieldForModel from "../for-model/FieldForModel";
import FormViewUi from "../ui-element/FormViewUi";
import Spacer from "../ui-element/Spacer";




interface IState
{
	phase: PhaseFlags;
	errors: IModelYiiFieldError[];
}




export default class PasswordUpdate extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		phase: PhaseFlags.NONE,
		errors: [],
	};




	submitForm(form: HTMLFormElement)
	{
		this.setState({phase: PhaseFlags.SAVING});

		const data = new FormData(form);
		PrettyPrintFormData(data);

		new Fetcher({"r": "node/update-password/do"})
		.post(data)
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(() =>
		{
			this.setState({errors: [], phase: PhaseFlags.NONE});
			toast.success(I18N.THE_DATA_HAS_BEEN_UPDATED_SUCCESSFULLY);
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors, phase: PhaseFlags.NONE});
		})
		.run();
	}



	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	renderMessage()
	{
		return (
		<Message icon>
			<Icon name="lock"/>

			<Message.Content>
				<Message.Header>{I18N.UPDATE_PASSWORD}</Message.Header>
			</Message.Content>

		</Message>);
	}




	renderForm()
	{
		const isSaving = this.state.phase === PhaseFlags.SAVING;
		const userId = this.props.optionsString || User.Instance.id;

		return (
		<Form onSubmit={(e: any) => this.submitForm(e.target)}>

			<input type="hidden" name="id" value={userId} />

			<FieldForModel
				errors = {this.state.errors}
				required
				field = "password"
				label = {I18N.PASSWORD}
				disabled = { isSaving }
				control =
				{
					<Form.Input
						type = "password"
						placeholder = {I18N.PASSWORD}
						autoComplete = "new-password"
					/>
				}
			/>

			<FieldForModel
				errors = {this.state.errors}
				required
				field = "repassword"
				label = {I18N.REPEAT_PASSWORD}
				disabled = { isSaving }
				control =
				{
					<Form.Input
						type = "password"
						placeholder = {I18N.REPEAT_THE_SAME_PASSWORD_HERE}
						autoComplete = "new-password"
					/>
				}
			/>

			<Button primary type="submit" loading={isSaving} disabled={isSaving}>
				{I18N.UPDATE_PASSWORD}
			</Button>

		</Form>);
	}




	render()
	{
		const userId = this.props.optionsString || User.Instance.id;

		return (
		<div className="wrapper">

			{ FormViewUi.BackButton("Back to user", "user-edit_" + userId) }

			<Spacer height = "25px"/>

			{ this.renderMessage()  }

			<Spacer height = "25px"/>

			{ this.renderForm() }

		</div>);
	}
}
