import IModelInOutCapacity from "../../model/IModelInOutCapacity";

export default class ModelsStacker
{
	static Run(models: IModelInOutCapacity[])
	{
		const stacks = [] as IModelInOutCapacity[][];

		for (let i = 0; i < models.length; ++i)
		{
			let s = 0;
			let foundPlace = false;

			while (!foundPlace)
			{
				if (stacks[s] === undefined)
				{
					stacks[s] = [];
				}

				if (!ModelsStacker.HasIntersect(models[i], stacks[s]))
				{
					stacks[s].push(models[i]);
					models[i].visual_stack = s;
					foundPlace = true;
				}

				s++;
			}
		}

		return stacks;
	}



	static HasIntersect(target: IModelInOutCapacity, stack: IModelInOutCapacity[])
	{
		for (const model of stack)
		{
			if (
				target.start_year_week <= model.end_year_week
				&&
				model.start_year_week <= target.end_year_week
			)
			return true;
		}

		return false;
	}
}
