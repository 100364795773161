import * as React from "react";
import { Button, Grid, Header, Icon, Image, Segment, Table } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import QueryUrl from "../../common/QueryUrl";
import IModelCompany from "../../model/IModelCompany";
import Emsp from "../ui-element/Emsp";
import PreWrapText from "../ui-element/PreWrapText";
import Spacer from "../ui-element/Spacer";




interface IProps
{
	model: IModelCompany;
	display: boolean;
	afterDelete: () => void;
}




interface IState
{
	deletingId: number;
}




export default class SingleCompany extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();

	state = {
		deletingId: -1,
	};


	render()
	{
		const {model} = this.props;

		const imageId = model.logo_file_id && model.cover_file_id;
		const address = this.makeAddress(model);

		return (
		<Segment style = {{display: this.props.display ? "block" : "none"}}>
			<Header as="h2">
				<a
					href = {`#company_${model.id}`}
					style = {{textDecoration: "underline"}}
				>
					{ model.name }
				</a>

				{this.renderModificationButtons(model, "right")}

				<Header.Subheader>
				{
					(model.location && model.location.title) &&
					<span><Icon name="map marker alternate" /> {model.location.title}<Emsp/></span>
				}
				{
					(!isNaN(model.number_of_employees) && model.number_of_employees > 0 ) &&
					<span><Icon name="users" /> {model.number_of_employees}<Emsp/></span>
				}
				{
					(!isNaN(model.departmentsCount) && model.departmentsCount > 0 ) &&
					<span><Icon name="sitemap" /> {model.departmentsCount}<Emsp/></span>
				}
				</Header.Subheader>
			</Header>

			<Grid>
				<Grid.Row>
					<Grid.Column width = {10}>
						<PreWrapText content = {model.description} />
						{
							Array.isArray(model.companies) && model.companies.length > 0
							&&
							<>
								<Spacer height = "10px"/>
								<Table basic="very" compact>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{I18N.DEPARTMENTS}</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{
											model.companies.map((department, index) => (
											<Table.Row key = {index}>
												<Table.Cell>
													{department.name}<Emsp/>
													{this.renderModificationButtons(department, "right")}
												</Table.Cell>
											</Table.Row>))
										}
									</Table.Body>
								</Table>
							</>
						}
					</Grid.Column>

					<Grid.Column width = {6}>
					{
						address &&
						<div className="detail">
							{
								imageId &&
								<>
									<Image src = {QueryUrl({"r": "node/file-download", "id": imageId})} />
									<Spacer height = "10px"/>
								</>
							}
							<PreWrapText content = {address} />
						</div>
					}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>);
	}




	makeAddress(model: IModelCompany)
	{
		const output = [] as string[];

		if (model.address) output.push(model.address + "\n");

		if (model.zip_code) output.push(model.zip_code);
		if (model.city) output.push(model.city + "\n");

		if (model.country) output.push(model.country + "\n");

		if (model.phone) output.push(`<b>${I18N.PHONE_NUMBER}: </b>${model.phone}\n`);

		if (model.website) output.push(`<b>${I18N.WEBSITE}: </b>${model.website}\n`);

		if (output.length === 0)
			return undefined;
		else
			return output.join(" ");
	}




	renderModificationButtons(model: IModelCompany, floated: string |undefined)
	{
		if (model.canDelete || model.canUpdate)
		{
			const p = {} as any;

			if (floated)
				p.floated = floated;

			return (
			<Button.Group
				{...p}
				icon
				size = "tiny"
			>
			{
				model.canUpdate
				&&
				<Button basic circular icon="edit" color="green"
					onClick={() => window.location.hash = `#company-edit_${model.id}`}
				/>
			}
			{
				model.canDelete
				&&
				<Button basic circular icon="trash alternate" color="red"
					loading = {this.state.deletingId === model.id}
					disabled = {this.state.deletingId === model.id}
					onClick = {() => this.deleteModel(model)}
				/>
			}
			</Button.Group>);
		}
		else
		{
			return undefined;
		}
	}




	deleteModel(model: IModelCompany)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({deletingId: model.id as number});

			new Fetcher({"r": "active/company/delete", "id": model.id})
			.delete()
			.delay(500)
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.setState({deletingId: -1});
				this.props.afterDelete();
			})
			.run();
		}
	}
}
