import _ from "lodash";
import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, Label, List, Segment } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import IModelCapacity from "../../model/IModelCapacity";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import FieldForModel from "../for-model/FieldForModel";
import InputSelector from "../ui-element/InputSelector";
import LocationTreeSelector from "../ui-element/LocationTreeSelector";
import YearWeekSpinner from "../ui-element/YearWeekSpinner";
import CapacityEditServiceSelector from "./CapacityEditServiceSelector";




interface IProps
{
	model?: Partial<IModelCapacity>;
	onSuccess: () => void;
}




interface IState
{
	selectedService: number | string;

	errors: IModelYiiFieldError[];
	phase: PhaseFlags;
}




export default class CapacityEdit extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		selectedService: undefined,

		errors: [] as IModelYiiFieldError[],
		phase: PhaseFlags.EDITING,
	};




	get isCreating()
	{
		return (this.props.model === undefined) || (this.props.model.id === undefined);
	}




	get hasAnApprovedResponse()
	{
		return	!this.isCreating &&
				this.props.model.capacityCompanies !== undefined &&
				_.find(this.props.model.capacityCompanies, (req) => req.status === 1) !== undefined;
	}




	formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
		const formData = new FormData(formEvent.target as HTMLFormElement);

		PrettyPrintFormData(formData);

		const {model} = this.props;

		const query: any = {"r": "node/capacity-calendar/create-capacity"};

		if (model !== undefined && model.id !== undefined)
		{
			query.r = "active/capacity/update";
			query.id = model.id;
		}

		this.setState({phase: PhaseFlags.SAVING});

		new Fetcher<IModelCapacity>(query)
		.post(formData)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({errors: [], phase: PhaseFlags.EDITING});

			this.props.onSuccess();
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors, phase: PhaseFlags.EDITING});
		})
		.run();
	}



	deleteModel()
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({phase: PhaseFlags.SAVING});

			new Fetcher({"r": "active/capacity/delete", "id": this.props.model.id})
			.delete()
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.setState({phase: PhaseFlags.NONE});
				this.props.onSuccess();
			})
			.run();
		}
	}




	render()
	{
		const {model} = this.props;
		const {errors, phase } = this.state;
		type T = Partial<IModelCapacity>;
		const isSaving = phase === PhaseFlags.SAVING;

		const me =
		{
			model,
			errors,
		};


		return (
		<Segment>
		<Form onSubmit={this.formOnSubmit.bind(this)}>

			<FieldForModel<T> {...me}
				disabled = {isSaving || this.hasAnApprovedResponse}
				label = {I18N.FROM}
				field = {"start_year_week"}
				control = { <YearWeekSpinner onlyFuture /> }
			/>

			<FieldForModel<T> {...me}
				disabled = {isSaving || this.hasAnApprovedResponse}
				label = {I18N.TO}
				field = {"end_year_week"}
				control = { <YearWeekSpinner onlyFuture /> }
			/>

			<FieldForModel<T> {...me}
				disabled = {isSaving}
				label = {I18N.PRIVATE_COMMENT}
				field = {"private_comment"}
				control = {<TextareaAutosize minRows = {2} rows = {2}/>}
			/>

			<FieldForModel<T> {...me}
				disabled = {isSaving || this.hasAnApprovedResponse}
				label = {I18N.PUBLIC_COMMENT}
				field = {"public_comment"}
				control = {<TextareaAutosize minRows = {2} rows = {2}/>}
			/>

			<CapacityEditServiceSelector {...me}
				disabled = {isSaving || this.hasAnApprovedResponse || !this.isCreating}
			/>

			<FieldForModel<T> {...me}
				disabled = {isSaving || this.hasAnApprovedResponse || !this.isCreating}
				label = {I18N.TYPE}
				field = {"type"}
				control =
				{
					<InputSelector
						options = {[
							{id: 0, text: I18N.OFFER_CAPACITY, value: 0},
							{id: 1, text: I18N.SEARCH_CAPACITY, value: 1},
						]}
					/>
				}
			/>

			<FieldForModel<T> {...me}
				label = {I18N.REGION}
				field = {"location_id"}
				disabled = {isSaving || this.hasAnApprovedResponse}
				control = {<LocationTreeSelector clearable/>}
			/>

			{/* {
				this.isCreating
				&&
				<NumberOfReceivers service_id = {selectedService} />
			} */}

			<Button
				disabled =	{!!(phase & PhaseFlags.SAVING)}
				loading =	{!!(phase & PhaseFlags.SAVING)}
				primary
				type="submit"
			>
				{this.isCreating ? I18N.ADD_NEW : I18N.SAVE}
			</Button>

			{
				!this.isCreating
				&&
				<Button
					disabled =	{!!(phase & PhaseFlags.SAVING)}
					loading =	{!!(phase & PhaseFlags.SAVING)}
					color = "red"
					type="reset"
					onClick = {this.deleteModel.bind(this)}
				>
					{I18N.DELETE}
				</Button>
			}


			{ this.renderRequestResponses() }
		</Form>
		</Segment>);
	}




	renderRequestResponses()
	{
		const {model} = this.props;

		if (this.hasAnApprovedResponse)
		{
			const approvedItems = _.filter(model.capacityCompanies, (req) => req.status === 1);

			console.log(approvedItems);

			return (
			<Segment.Group>
				<Segment raised>
					<Label color="green" ribbon> {I18N.APPROVED} </Label>

					<List divided relaxed>
						{
							approvedItems.map((req) =>
							{
								const names = [];
								let id = req.company.id;

								if (req.company.parent)
								{
									names.push(req.company.parent.name);
									id = req.company.parent.id;
								}

								names.push(req.company.name);

								return (
								<List.Item key={req.id}>
									<List.Content>
										<List.Header as = "a" href = {"#company_" + id}>
											{names.join(" / ")}
										</List.Header>

										<List.Description>
											{req.company.description}
										</List.Description>
									</List.Content>
								</List.Item>);
							})
						}
					</List>
				</Segment>
			</Segment.Group>);
		}
	}
}
