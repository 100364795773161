import * as React from "react";
import I18N from "../../common/i18n/I18N";
import IModelCapacity from "../../model/IModelCapacity";
import IModelCapacityCompany from "../../model/IModelCapacityCompany";
import IModelInOutCapacity from "../../model/IModelInOutCapacity";




interface IProps
{
	highlighted: number;
	models: IModelInOutCapacity[];
}




export default class Legend extends React.Component<IProps>
{
	render()
	{
		const {highlighted, models} = this.props;
		const model = models.find((m) => m.id === highlighted);

		const h = (type: "offer" | "request", dir: "in" | "out") =>
		{
			if (model === undefined)
			{
				return true;
			}
			if (model.dir === dir)
			{
				let t = 0;

				if (model.dir === "in")
					t = (model.data as IModelCapacityCompany).capacity.type;
				else
				t = (model.data as IModelCapacity).type;

				return type === ["offer", "request"][t];
			}

			return false;
		};

		return (
		<div className="Legend">
			<div className="wrapper">
				<dl>
					<dd data-name="offer-sent" data-highlighted = {h("offer", "out")} />
					<dt>{I18N.SENT_OFFERS}</dt>

					<dd data-name="offer-received" data-highlighted = {h("offer", "in")} />
					<dt>{I18N.RECEIVED_OFFERS}</dt>

					<dd data-name="request-sent" data-highlighted = {h("request", "out")} />
					<dt>{I18N.SENT_REQUESTS}</dt>

					<dd data-name="request-received" data-highlighted = {h("request", "in")} />
					<dt>{I18N.RECEIVED_REQUESTS}</dt>
				</dl>
			</div>
		</div>);
	}
}
