import * as React from "react";
import { DropdownItemProps, Form, SelectProps } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import IModelLocation from "../../model/IModelLocation";




interface IState
{
	options: DropdownItemProps[];
	value: string;
}




export default class LocationTreeSelector extends React.Component<Partial<SelectProps>, IState>
{
	state =
	{
		options: undefined as DropdownItemProps[],
		value: undefined,
	};



	fetchers = Fetcher.CreateList();



	componentDidMount(): void
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	async fetchData()
	{
		this.setState({options: undefined});

		new Fetcher<IModelLocation[]>(
		{
			"r": "active/location/all-tree",
			"parent_id":  "null",
		})
		.get()
		.writeToCacheOnSuccess(true)
		.readFromCacheIfYoungerThan(5 * 1000 * 60) // 5 minutes
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			const options: DropdownItemProps[] = [];

			const iteratePush = (models: IModelLocation[], lead: string) =>
			{
				for (const model of models)
				{
					options.push({
						key: model.id,
						value: model.id,
						text: (lead === undefined ? "" : ` ${lead} `) + model.title,
					});
					iteratePush(
						model.locations,
						(lead === undefined ? "―" : lead + "―"),
					);
				}
			};

			iteratePush(data, undefined);

			this.setState({options});
		})
		.run();
	}




	render()
	{
		const {options} = this.state;

		if (options === undefined)
		{
			return <Form.Input loading disabled value = {I18N.PLEASE_WAIT}/>;
		}
		else
		{
			return <Form.Field>
				<input
					type="hidden"
					name={this.props.name}
					value= { (this.state.value === undefined || this.state.value === null) ? "" : this.state.value.toString() }
				/>

				<Form.Select
					{...this.props}
					onChange = { (event, data) => this.setState({value: data.value.toString()}) }
					options= { options }
				/>
			</Form.Field>;
		}
	}
}
