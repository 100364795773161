import * as React from "react";
import { Button, Header, List, Modal, Popup } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import IModelLocation from "../../model/IModelLocation";
import IApplicationProps from "../application-collection/IApplicationProps";
import WaitingUiElement from "../ui-element/WaitingUiElement";
import SingleLocationEditor from "./SingleLocationEditor";
import "./Style.scss";




interface IState
{
	phase: PhaseFlags;
	models: IModelLocation[];
	editionTarget: Partial<IModelLocation>;
}




export default class LocationCRUD extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();




	state: IState =
	{
		models: [],
		phase: PhaseFlags.NONE,
		editionTarget: undefined,
	};




	componentDidMount()
	{
		this.fetchTable();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchTable()
	{
		this.setState({phase: PhaseFlags.FETCHING, models: []});

		new Fetcher<IModelLocation[]>(
		{
			"r": "active/location/all-tree",
			"parent_id":  "null",
		})
		.delay(500)
		.get()
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({models: data, phase: PhaseFlags.NONE});
		})
		.run();
	}




	renderModelsTree(models: IModelLocation[], depth: number)
	{
		const Root = depth === 0 ? List : List.List;
		const RootProps = depth === 0 ? {divided: true, relaxed: true} : {};

		if (depth !== 0 && (models === undefined || models.length === 0))
			return undefined;

		return (
			<Root {...RootProps}>
			{
				models.map((model) =>
				{
					return (
					<List.Item key={model.id}>
						<List.Icon name="caret square right"/>
						<List.Content>
							<Popup
								flowing
								hoverable
								position = "left center"
								trigger = { <List.Header as="h5">{model.title}</List.Header> }
							>
								<Button.Group vertical labeled icon size="tiny" basic>
									<Button icon="edit" content={I18N.EDIT}
										onClick = { () =>
										{
											this.setState({editionTarget: model});
										}}
									/>

									<Button icon="delete" content={I18N.DELETE}
										onClick = {() =>
										{
											this.deleteModel(model);
										}}
									/>

									<Button icon="indent" content={I18N.ADD_NEW}
										onClick = { () =>
										{
											this.setState({editionTarget: {parent_id: model.id}});
										}}
									/>
								</Button.Group>
							</Popup>

							<List.Description>
								{model.description}
							</List.Description>

							{ this.renderModelsTree(model.locations, depth + 1) }
						</List.Content>
					</List.Item>);
				})
			}
			{
				depth === 0
				&&
				<List.Item>
					<List.Icon name="plus square" color="blue"/>
					<List.Content>
						<List.Header>
							<Header color="blue" as="h5" onClick = { () =>
							{
								this.setState({editionTarget: {parent_id: null}});
							}}>
								{I18N.ADD_NEW}
							</Header>
						</List.Header>
					</List.Content>
				</List.Item>
			}
			</Root>
		);
	}

	deleteModel(model: IModelLocation)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			this.setState({phase: PhaseFlags.FETCHING});

			new Fetcher({"r": "active/location/delete", "id": model.id})
			.delete()
			.delay(500)
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.fetchTable();
			})
			.run();
		}
	}




	render()
	{
		const {phase, editionTarget, models} = this.state;


		return(
		<div className="wrapper">
			{/* <Message icon>
				<Icon name="help"/>

				<Message.Content>
					<Message.Header>Do you want to Create or Edit Region?</Message.Header>
					Here you can describe all the regions.
					To add a new one fill the blank form at the end of the list.
				</Message.Content>
			</Message> */}

			{
				phase === PhaseFlags.FETCHING
				?
				WaitingUiElement.Tree()
				:
				this.renderModelsTree(models, 0)
			}

			{
				editionTarget !== undefined
				&&
				this.renderModal(editionTarget)
			}
		</div>);
	}




	renderModal(editionTarget: Partial<IModelLocation>)
	{
		return (
		<Modal
			open={true} closeOnEscape={true} closeOnDimmerClick={true}
			onClose = {() => this.setState({editionTarget: undefined})}
		>
			<Modal.Content>
				<SingleLocationEditor
					model = {editionTarget}
					onSuccess = {() =>
					{
						this.setState({editionTarget: undefined});
						this.fetchTable();
					}}
				/>
			</Modal.Content>
		</Modal>);
	}
}
