import { type } from "os";
import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, Icon, Message } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import User from "../../common/User";
import IModelCompany from "../../model/IModelCompany";
import IModelUser from "../../model/IModelUser";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";
import FieldForModel from "../for-model/FieldForModel";
import LocationTreeSelector from "../ui-element/LocationTreeSelector";
import ModelSelector from "../ui-element/ModelSelector";
import Spacer from "../ui-element/Spacer";
import FormViewUi from "./../ui-element/FormViewUi";
import CompanyTypeSelector from "./CompanyTypeSelector";
import DepartmentsList from "./DepartmentsList";
import ServicesList from "./ServicesList";




interface IState
{
	model: Partial<IModelCompany>;
	errors: IModelYiiFieldError[];
	phase: PhaseFlags;
}




export default class CompanyEdit extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		model: undefined as IModelCompany,
		errors: [],
		phase: PhaseFlags.NONE,
	};




	componentDidMount()
	{
		/**
		 * Options vaiations:
		 * - {parent_id}_ for creating a new department under the parent company (company.id === parnet_id).
		 * - {company_id} for editing an already added company/department (company.id === company_id).
		 * - [EMPTY] for creating a new company only available for system admins.
		 */

		if (typeof this.props.optionsString === "string")
		{
			const parts = this.props.optionsString.split("_");
			const id = parseInt(parts[0]);

			if (parts.length === 1)
			{
				if (isNaN(id)) // Creating
				{
					const model = {id: undefined};
					this.setState({model});
				}
				else
				{
					this.fetchModel(id);
				}
			}
			else
			{
				const model = {id: undefined, parent_id: id};
				this.setState({model});
			}
		}
		else
		{
			const model = {id: undefined};
			this.setState({model});
		}
	}




	fetchModel(id: number)
	{
		this.setState({model: undefined});

		new Fetcher({
			"r": "active/company/view",
			"expand": ["companies", "parent", "companyServices.service"].join(","),
			id,
		})
		.get()
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({model: data});
		})
		.run();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	async formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
		const formData = new FormData(formEvent.target as HTMLFormElement);

		const query: any = {"r": "active/company/create"};

		if (this.state.model !== undefined && this.state.model.id !== undefined)
		{
			query.r = "active/company/update";
			query.id = this.state.model.id;
		}

		this.setState({phase: PhaseFlags.SAVING});

		new Fetcher<IModelCompany>(query)
		.post(formData)
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({errors: [], phase: PhaseFlags.NONE});

			if (this.state.model.id === undefined)
			{
				window.location.hash = "#company-edit_" + data.id;
			}
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors, phase: PhaseFlags.NONE});
		})
		.run();
	}



	render()
	{
		return (
		<React.Fragment>
			{
				this.state.model === undefined
				?
				<div className="wrapper">
					{ FormViewUi.Waiting() }
				</div>
				:
				this.renderForm()
			}
		</React.Fragment>);
	}




	renderForm()
	{
		const isCreating = (this.state.model.id === undefined);
		const isSaving = this.state.phase === PhaseFlags.SAVING;
		const disabled = {disabled: isSaving};

		const isCompany = this.state.model.parent_id === undefined || this.state.model.parent_id === null;

		return (
		<Form onSubmit={this.formOnSubmit.bind(this)} className="wrapper">

			<Button as = "a" href = "#companies">
				<Icon name="arrow alternate circle left"/> {I18N.COMPANIES}
			</Button>

			{ this.renderMessage() }

			{
				isCreating && this.state.model.parent_id !== undefined
				&&
				<input type="hidden" name="parent_id" value={this.state.model.parent_id} />
			}

			<FieldForModel<IModelCompany> {...this.state} {...disabled}
				required
				field = "name"
				label = {I18N.NAME}
				control = {<Form.Input size="huge" placeholder = {isCompany ? I18N.COMPANYS_NAME : I18N.DEPARTMENTS_NAME} />}
			/>


			{
				User.IsGod
				&&
				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					required
					field = "type"
					label = {I18N.TYPE}
					control = {<CompanyTypeSelector placeholder={type} />}
				/>
			}


			<Spacer/>

			<FieldForModel<IModelCompany> {...this.state} {...disabled}
				field = "description"
				label = {I18N.DESCRIBE_IT + "..."}
				control =
				{<TextareaAutosize
					minRows = {2}
					rows = {2}
					placeholder = {I18N.DESCRIBE_THIS_COMPANY}
				/>}
			/>

			<FieldForModel<IModelCompany> {...this.state} {...disabled}
				field = "equipment"
				label = {I18N.EQUIPMENT + "..."}
				control =
				{<TextareaAutosize
					minRows = {2}
					rows = {2}
					placeholder = {I18N.EQUIPMENT}
				/>}
			/>

			<FieldForModel<IModelCompany> {...this.state} {...disabled}
				field = "location_id"
				label = {I18N.SPHERE_OF_ACTIVITY}
				control = {<LocationTreeSelector/>}
			/>

			<FieldForModel<IModelCompany> {...this.state} {...disabled}
				field = "number_of_employees"
				label = {I18N.EMPLOYEES}
				control =
				{<Form.Input
					type="number"
					min = {0}
					step = {1}
				/>}
			/>

			{/* <FieldForModel<IModelCompany> {...this.state} {...disabled}
				field = "contact"
				label = {I18N.DESCRIBE_HOW_TO_CONTACT_THIS_COMPANY + "..."}
				control =
				{<TextareaAutosize
					minRows = {2}
					rows = {2}
					placeholder = {I18N.DESCRIBE_THIS_COMPANY}
				/>}
			/> */}

			<Spacer/>

			<Form.Group widths="equal">
				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					field = "country"
					label = {I18N.COUNTRY + "..."}
					control =
					{<Form.Input
						placeholder = {I18N.COUNTRY}
					/>}
				/>

				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					field = "city"
					label = {I18N.CITY + "..."}
					control =
					{<Form.Input
						placeholder = {I18N.CITY}
					/>}
				/>

				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					field = "zip_code"
					label = {I18N.ZIP_CODE + "..."}
					control =
					{<Form.Input
						placeholder = {I18N.ZIP_CODE}
					/>}
				/>
			</Form.Group>

			<FieldForModel<IModelCompany> {...this.state} {...disabled}
				field = "address"
				label = {I18N.ADDRESS + "..."}
				control =
				{<Form.Input
					placeholder = {I18N.ADDRESS}
				/>}
			/>

			<Spacer/>

			<Form.Group widths="equal">
				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					field = "website"
					label = {I18N.WEBSITE + "..."}
					control =
					{<Form.Input
						placeholder = {I18N.WEBSITE}
					/>}
				/>

				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					field = "email"
					label = {I18N.EMAIL + "..."}
					control =
					{<Form.Input
						placeholder = {I18N.EMAIL}
					/>}
				/>

				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					field = "phone"
					label = {I18N.PHONE_NUMBER + "..."}
					control =
					{<Form.Input
						placeholder = {I18N.PHONE_NUMBER}
					/>}
				/>
			</Form.Group>

			{
				!isCreating
				&&
				<FieldForModel<IModelCompany> {...this.state} {...disabled}
					field = "contact_user_id"
					label = {I18N.CONTACT}
					control =
					{<ModelSelector<IModelUser>
						query = {{"r" :  "node/company-edit/contact-users", "company_id": this.state.model.id}}
						map = {(m, i) => ({key: i, text: m.nickname || m.email, value: m.id})}
					/>}
				/>
			}

			<Button
				primary
				type="submit"
				disabled={isSaving}
				loading={isSaving}
			>
				{isCreating ? I18N.ADD_NEW : I18N.SAVE}
			</Button>

			<Spacer/>

			{
				!isCreating && (this.state.model.companies.length === 0 || !isCompany)
				&&
				<ServicesList
					models = {this.state.model.companyServices}
					companyId = {this.state.model.id}
					onChange = {() => this.fetchModel(this.state.model.id)}
				/>
			}

			{
				!isCreating && isCompany
				&&
				<DepartmentsList
					companyId={this.state.model.id}
					models = {this.state.model.companies}
					onChange = {() => this.fetchModel(this.state.model.id)}
				/>
			}

		</Form>
		);
	}




	renderMessage()
	{
		return (
		<Message icon>
			<Icon name="help"/>

			<Message.Content>
				{
					<Message.Header>{I18N.UPDATE_YOUR_COMPANY_INFORMATION}</Message.Header>
				}
				{I18N.THE_CONTACT_USER_WILL_BE_VISIBLE_TO_EVERYONE_AND_WILL_RECEIV__72}<br/>
			</Message.Content>
		</Message>);
	}
}
