import * as React from "react";
import { Button, Grid, Icon, Segment} from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";
import IApplicationProps from "../application-collection/IApplicationProps";
import Emsp from "../ui-element/Emsp";
import InCapacities from "./InCapacities";
import OutCapacities from "./OutCapacities";



type DirType = "in_0" | "in_1" | "out_0" | "out_1";
type Order = 3 | 4;



interface IState
{
	dirType: DirType;
	order: Order;
}




export default class CapacityCRUD extends React.Component<IApplicationProps, IState>
{
	state =
	{
		dirType: "in_0" as DirType,
		order: 3 as Order,
	};




	render()
	{
		const dirTypes: Array<{ id: DirType, title: string}> =
		[
			{id: "in_0",	title: I18N.RECEIVED_OFFERS },
			{id: "in_1",	title: I18N.RECEIVED_REQUESTS },
			{id: "out_0",	title: I18N.SENT_OFFERS },
			{id: "out_1",	title: I18N.SENT_REQUESTS },
		];

		return (
		<div className="wrapper">
			<style>
				{ `.ApplicationCollection {align-items: start !important;}` }
			</style>

			<Button as = "a" href="#capacity-calendar">
				<Icon name="calendar alternate outline"/> {I18N.CALENDAR}
			</Button>

			<div style={{marginTop: "50px"}}></div>

			<Segment secondary>
				<Grid divided columns={2}>
					<Grid.Column>
						<Button.Group vertical fluid basic>
							{
								dirTypes.map((e) =>
									<Button
										key={e.id} toggle
										active={this.state.dirType === e.id}
										onClick = {() => this.setState({dirType: e.id})}
									>
										{e.title}
									</Button>)
							}
						</Button.Group>
					</Grid.Column>
					<Grid.Column>
						{I18N.SORT_BY_CREATION_DATE}: <Emsp/>
						<Button
							onClick = { () => this.setState({order: ((this.state.order - 2) % 2) + 3 as Order}) }
						>
							<Icon name = {this.state.order === 3 ? "sort content descending" : "sort content ascending"}/>
							{ this.state.order === 3 ? I18N.DESC : I18N.ASC }
						</Button>
					</Grid.Column>
				</Grid>
			</Segment>

			<React.Fragment key = {this.state.order.toString()}>
				{
					this.state.dirType === "in_0" &&
					<InCapacities  type = {0} key="in_0"  order={this.state.order} />
				}
				{
					this.state.dirType === "in_1" &&
					<InCapacities  type = {1} key="in_1"  order={this.state.order} />
				}
				{
					this.state.dirType === "out_0" &&
					<OutCapacities type = {0} key="out_0" order={this.state.order} />
				}
				{
					this.state.dirType === "out_1" &&
					<OutCapacities type = {1} key="out_1" order={this.state.order} />
				}
			</React.Fragment>
		</div>);
	}
}
