import * as React from "react";
import { Button, Divider, Form, Icon, Message, Ref, Table } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import IModelCapacity from "../../model/IModelCapacity";
import IModelLocation from "../../model/IModelLocation";
import IModelService from "../../model/IModelService";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";
import FieldForModel from "../for-model/FieldForModel";
import ModelSelector from "../ui-element/ModelSelector";
import YearWeekSpinner from "../ui-element/YearWeekSpinner";
import IncludeCompaniesCheckbox from "./IncludeCompaniesCheckbox";




interface IState
{
	categories: IModelCapacity[][];
	errors: IModelYiiFieldError[];

	selectedSector: number;
	selectedService: number;

	isSearching: boolean;
	hadResult: boolean;
	isSendingRequest: boolean;
}




/* tslint:disable: no-bitwise */
export default class MakeCapacityRequest extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();
	formRef = React.createRef<HTMLFormElement>();


	state =
	{
		categories: undefined,
		errors: [],

		selectedSector: 1,
		selectedService: 8,

		hadResult: false,
		isSearching: false,
		isSendingRequest: false,
	};




	async submitForm(goal: "to-search"|"to-send-request")
	{
		const formData = new FormData(this.formRef.current);

		PrettyPrintFormData(formData);

		this.setState({errors: [], isSearching: true});

		await new Fetcher<IModelCapacity[]>(
		{
			"r": goal === "to-search" ? "capacity/search-offers" : "capacity/send-request",
		})
		.appendTo(this.fetchers)
		.post(formData)
		.onSuccess(({data}) =>
		{
			if (goal === "to-search")
			{
				this.setState({hadResult: data.length > 0});

				this.displaySearchResult(
					data,
					formData.get("location_id"),
					formData.get("start_year_week"),
					formData.get("end_year_week"),
				);
			}
			else
			{
				window.location.hash = "capacity-requests";
			}
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors});
		})
		.run();

		this.setState({isSearching: false});
	}




	displaySearchResult(models: any[], location_id: any, start_year_week: any, end_year_week: any)
	{
		// 										00  01  10  11
		const categories: IModelCapacity[][] = [[], [], [], []];

		for (const model of models)
		{
			let index = 0b00;

			if (model.company_location_id === parseInt(location_id))
			{
				index |= 0b01;
			}

			if (model.start_year_week <= parseInt(end_year_week) && parseInt(start_year_week) <= model.end_year_week)
			{
				index |= 0b10;
			}

			categories[index].push(model);
		}

		this.setState({categories, errors: []});
	}




	renderResultTable()
	{
		const renderBit = (num: number, check: number) =>
		{
			return (
			<Table.Cell textAlign="center">
				<Icon
					color = {((num & check) === 0) ? "red" : "green"		}
					name =  {((num & check) === 0) ? "delete" : "checkmark" }
					size="large"
				/>
			</Table.Cell>
			);
		};

		return (
		<Table compact celled definition>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell />
					<Table.HeaderCell>{I18N.PERIOD}</Table.HeaderCell>
					<Table.HeaderCell>{I18N.REGION}</Table.HeaderCell>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{
					this.state.categories.map((models: IModelCapacity[], index: number) =>
					{
						if (models.length > 0)
						{
							return (
							<Table.Row key = {index}>
								<Table.Cell>
									<IncludeCompaniesCheckbox models={models} />
								</Table.Cell>

								{ renderBit(index, 0b10) }
								{ renderBit(index, 0b01) }
							</Table.Row>);
						}
						else
						{
							return undefined;
						}
					})
				}
    		</Table.Body>

			<Table.Footer fullWidth>
				<Table.Row>
					<Table.HeaderCell colSpan="3">
						<Button
							floated="right"
							icon labelPosition="left"
							primary
							type="submit"
							onClick = {() => this.submitForm("to-send-request")}
						>
							<Icon name="mail" /> {I18N.SEND_REQUESTS}
						</Button>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Footer>
		</Table>
		);
	}




	render()
	{
		return (
		<div className="CapacityPool wrapper">
			<Ref innerRef={this.formRef}>
			<Form ref = {this.formRef}>

				<FieldForModel<IModelCapacity>
					field = "start_year_week"
					control = {<YearWeekSpinner/>}
					errors = {this.state.errors}
				/>

				<Divider/>

				<FieldForModel<IModelCapacity>
					field = "end_year_week"
					control = {<YearWeekSpinner defaultValue="201933"/>}
					errors = {this.state.errors}
				/>

				<Divider/>

				<Form.Group widths="equal">
					<ModelSelector<IModelService>
						query = {{"r": "active/service/find-by-depth", "depth": 1}}
						map={ (m, i) => ({key: i, text: m.name, value: m.id}) }
						onChange = {(event, data) => this.setState({selectedSector: data.value as number, selectedService: -1})}
						label={I18N.FIELD}
						defaultValue = {this.state.selectedSector}
					/>

					{
						(this.state.selectedSector !== undefined && this.state.selectedSector > 0)
						&&
						<ModelSelector<IModelService>
							key={this.state.selectedSector}
							query = {{"r": "active/service/index", "parent_id": this.state.selectedSector}}
							map={ (m, i) => ({key: i, text: m.name, value: m.id}) }
							clearable
							placeholder = "All the services in selected sector"
							label={I18N.SERVICE}
							onChange = {(event, data) => this.setState({selectedService: data.value as number})}
							name="service_id"
							defaultValue = {this.state.selectedService}
						/>
					}

				</Form.Group>

				<ModelSelector<IModelLocation>
					query = {{"r": "active/location/index"}}
					map={ (m, i) => ({key: i, text: m.title, value: m.id}) }
					clearable
					placeholder = "All the regions"
					label ={I18N.REGION}
					name="location_id"
					defaultValue = {1}
				/>

				<Button
					primary
					disabled = {this.state.selectedService === -1 || this.state.isSearching}
					loading = {this.state.isSearching}
					onClick = {() => this.submitForm("to-search")}
				>
					<Icon name="search" />
					{I18N.SEARCH}
				</Button>

				{
					this.state.categories !== undefined
					&&
					this.state.hadResult
					&&
					this.renderResultTable()
				}
				{
					this.state.categories !== undefined
					&&
					!this.state.hadResult
					&&
					<Message>{I18N.NO_MATCH}</Message>
				}
			</Form>
			</Ref>
		</div>);
	}
}
