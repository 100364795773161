import * as React from "react";
import { Message, Placeholder } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";



interface IProps
{
	id: number;
}



interface IState
{
	data: string;
	errors: any;
}




export default class AuthImage extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		data: undefined,
		errors: undefined,
	};




	async fetchData()
	{
		this.setState({errors: undefined, data: undefined});

		new Fetcher<ArrayBuffer>({"r": "active/file/download", "id": this.props.id})
		.get()
		.config({responseType: "arraybuffer"})
		.appendTo(this.fetchers)
		.readFromCacheIfYoungerThan(-1)
		.writeToCacheOnSuccess(true)
		.onSuccess(({data}) =>
		{
			const bytes = new Uint8Array(data);
			const binary = bytes.reduce((d, b) => d += String.fromCharCode(b), "");

			this.setState({data: binary, errors: undefined});
		})
		.onError((error) =>
		{
			if (error.response)
			{
				const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
				const obj = JSON.parse(decodedString);
				this.setState({errors: obj});
			}
			this.setState({errors: error, data: undefined});
		})
		.run();
	}



	componentDidMount(): void
	{
		this.fetchData();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	render()
	{
		const {data, errors} = this.state;
		const isWaiting = (data === undefined && errors === undefined);


		return (
			<div className="image">
				{
					(!isWaiting)
					?
					(
						this.state.data === undefined
						?
						<Message>{errors}</Message>
						:
						<img src={"data:image/jpeg;base64," + btoa(data)} style={{maxWidth: "100%"}} alt={this.props.id.toString()}/>
					)
					:
					<Placeholder fluid>
						<Placeholder.Image rectangular />
					</Placeholder>
				}
			</div>
		);
	}
}
