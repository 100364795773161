import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import {  Button, Form, Icon, Modal } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import IModelFeedback from "../../model/IModelFeedback";
import FieldForModel from "../for-model/FieldForModel";



function FeedbackModal() {

    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);

    const fetchers = Fetcher.CreateList();

    // useEffect(() => () => Fetcher.CancelAll(fetchers), [fetchers]);

    function formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
        const formData = new FormData(formEvent.target as HTMLFormElement);
        const query: any = {"r": "node/user-feedback"};

        setSending(true);

        new Fetcher<IModelFeedback>(query)
        .post(formData)
        .delay(500)
        .appendTo(fetchers)
        .onSuccess(() =>
        {
            setSending(false);
            setOpen(false);
            toast.info(I18N.FEEDBACK_IS_SENT);
        })
        .onError((err) =>
        {
            setSending(false);
        })
        .run();
    }

    function trigger()
    {
        return (
        <div onClick={() => setOpen(true)} style={{cursor: "pointer"}}>
            <Icon name="smile outline" size="large"/>
            <p>{I18N.FEEDBACK}</p>
        </div>);
    }

    return (
    <Modal dimmer onClose={() => setOpen(false)} open={open} trigger={trigger()}>
        <Modal.Header>{I18N.FEEDBACK}</Modal.Header>
        <Modal.Content>
        <Form onSubmit={formOnSubmit}>
            <FieldForModel<Partial<IModelFeedback>>
                field= "content"
                label= { I18N.DO_YOU_HAVE_ANY_COMMENTS_OR_SUGGESTIONS_SEND_US_FEEDBACK }
                control =
                {<TextareaAutosize
                    minRows = {2}
                    rows = {2}
                />}
                disabled = { sending }
            />
            <Button loading={sending} type="submit">{I18N.SEND}</Button>
        </Form>
        </Modal.Content>
    </Modal>);
}


export default FeedbackModal;
