import moment from "moment";
import * as React from "react";
import CapacityCalendar from "./CapacityCalendar";
import DecodeYW from "./DecodeYW";
import EncodeYW from "./EncodeYW";
import WeekSpan from "./WeekSpan";




interface IProps
{
	yw: number;
	selected: boolean;
	parentCapacityCalendar: CapacityCalendar;
}




export default class Week extends React.Component<IProps>
{
	get monthName()
	{
		const {yw} = this.props;

		const {year, week} = DecodeYW(yw);

		const m = moment().year(year).isoWeek(week);

		let monthName: string;

		if (
			(m.weekday(0).date() === 1)
			||
			(m.weekday(0).month() !== m.weekday(6).month() && week !== 53 && week !== 52)
			||
			week === 1
		)
		{
			monthName = m.weekday(6).format("MMMM");
		}

		return monthName;
	}



	render()
	{
		const {yw, selected, parentCapacityCalendar} = this.props;
		const {isSelectionRunning, stackLength, models} = parentCapacityCalendar.state;

		const currentYw = EncodeYW(moment().year(), moment().isoWeek());

		const sign = Math.sign(yw - currentYw);

		const cursor =
		(
			sign < 0 ? "not-allowed" :
			(isSelectionRunning ? "grabbing" : "grab")
		);

		return (
		<div
			className="Week"
			data-selected = { selected.toString() }
			style = {{ cursor }}
			data-break = {this.monthName !== undefined}
			data-sign = {sign}
			data-yw = {yw}
		>
			<div className="header">
				{"kw " + DecodeYW(yw).week}
			</div>

			<div className="spans" style={{height: (stackLength * 25) + "px"}}>
			{
				models.map((model, index) =>
				{
					if (model.start_year_week <= yw && yw <= model.end_year_week)
					{
						return (
						<WeekSpan
							key = {(yw * 100) + index}
							yw = {this.props.yw}
							model = {model}
							parentCapacityCalendar = {this.props.parentCapacityCalendar}
						/>);
					}
					else
					{
						return undefined;
					}
				})
			}
			</div>

			{
				this.monthName !== undefined
				&&
				<footer>{this.monthName}</footer>
			}
		</div>);
	}
}
