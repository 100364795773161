import * as React from "react";
import { Icon, Message } from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import IApplicationProps from "../application-collection/IApplicationProps";




export default class Logout extends React.Component<IApplicationProps>
{
	componentDidMount()
	{
		User.Logout();
	}




	render()
	{
		return (
			<div className="Logout wrapper">
				<Message icon>
					<Icon name="sign-out"/>

					<Message.Content>
						<Message.Header>{I18N.YOU_HAVE_SUCCESSFULLY_LOGGED_OUT}</Message.Header>
						<a href="#login">{I18N.LOGIN}</a>
					</Message.Content>
				</Message>
			</div>
		);
	}
}
