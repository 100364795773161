import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form, Icon, Message, Tab } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import IModelCompany from "../../model/IModelCompany";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import IApplicationProps from "../application-collection/IApplicationProps";
import FieldForModel from "../for-model/FieldForModel";
import Spacer from "../ui-element/Spacer";
import CompanySelector from "./CompanySelector";




interface IState
{
	model: any;
	errors: IModelYiiFieldError[];
	phase: PhaseFlags;
}




export default class RequestRegistration extends React.Component<IApplicationProps, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		model: undefined,
		errors: [],
		phase: PhaseFlags.NONE,
	};




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
		const formData = new FormData(formEvent.target as HTMLFormElement);

		PrettyPrintFormData(formData);

		const query: any = {"r": "node/request-registration/create"};

		this.setState({phase: PhaseFlags.SAVING});

		new Fetcher<IModelCompany>(query)
		.post(formData)
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			console.log(data);
			this.setState({phase: PhaseFlags.SUCCESS});
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors, phase: PhaseFlags.NONE});
		})
		.run();
	}



	render()
	{
		return (
		<div className="wrapper">
			{
				this.state.phase === PhaseFlags.SUCCESS
				?
				this.renderSuccessMessage()
				:
				this.renderForm()
			}
		</div>);
	}




	renderSuccessMessage()
	{
		return (
		<Message icon>
			<Icon name="inbox"/>

			<Message.Content>
				<Message.Header>{I18N.YOUR_REQUEST_HAS_BEEN_SAVED_SUCCESSFULLY}!</Message.Header>
				{
					// tslint:disable-next-line: max-line-length
					I18N.YOUR_REQUEST_WILL_BE_PROCESSED_BY_OUR_STAFF_IN_NEXT_24_HOURS
				}
			</Message.Content>
		</Message>);
	}




	renderForm()
	{
		const isSaving = this.state.phase === PhaseFlags.SAVING;
		const disabled = {disabled: isSaving};

		return (
		<Form onSubmit={this.formOnSubmit.bind(this)}>

			<Tab
				menu={{ secondary: true, pointing: true }}
				panes={
					[
						{
							menuItem: "Join a company",
							render: () => (
							<>
								<input type = "hidden" name = "action" value = "join" />
								<FieldForModel {...this.state} {...disabled}
									required
									field = "company_id"
									control = {<CompanySelector placeholder = {"Select a company"}/>}
								/>
							</>),
						},
						{
							menuItem: "Create a new company",
							render: () => (
							<>
								<input type = "hidden" name = "action" value = "create" />
								<FieldForModel {...this.state} {...disabled}
									required
									field = "company_name"
									control = {<Form.Input placeholder = {I18N.COMPANYS_NAME} />}
								/>
							</>),
						},
					]
				}
			/>

			<Spacer height = "20px" />

			<FieldForModel {...this.state}
				required
				field = "email"
				label = {I18N.EMAIL}
				control = {<Form.Input placeholder = {I18N.UNIQUE_EMAIL_ADDRESS} />}
				disabled = { isSaving }
			/>

			<FieldForModel<IModelCompany> {...this.state} {...disabled}
				field = "description"
				label = { I18N.REMARKS }
				control =
				{<TextareaAutosize
					minRows = {5}
					rows = {5}
				/>}
			/>

			<Button
				primary
				type="submit"
				disabled={isSaving}
				loading={isSaving}
			>
				{ I18N.SEND }
			</Button>

			<Spacer/>

		</Form>
		);
	}




	renderMessage()
	{
		return (
		<Message icon>
			<Icon name="help"/>

			<Message.Content>
				{
					<Message.Header>{I18N.UPDATE_YOUR_COMPANY_INFORMATION}</Message.Header>
				}
				{I18N.THE_CONTACT_USER_WILL_BE_VISIBLE_TO_EVERYONE_AND_WILL_RECEIV__72}<br/>
			</Message.Content>
		</Message>);
	}
}
