import * as React from "react";
import {Checkbox} from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";
import IModelCapacity from "../../model/IModelCapacity";




interface IState
{
	checked: boolean;
}



interface IProps
{
	models: IModelCapacity[];
}




/* tslint:disable: no-bitwise */
export default class IncludeCompaniesCheckbox extends React.Component<IProps, IState>
{
	state =
	{
		checked: false,
	};




	render()
	{
		const {models} = this.props;

		return (
		<div>
			<Checkbox
				label = {models.length + " " + I18N.COMPANIES}
				onChange = {(event, data) => this.setState({checked: data.checked})}
				checked = {this.state.checked}
			/>

			{
				this.state.checked
				&&
				models.map((model: any) =>
				{
					return <input key={model.id} type="hidden" name="companies[]" value={model.encrypted_company_id} />;
				})
			}
		</div>
		);
	}
}
