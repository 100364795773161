import { useState } from "react";
import * as React from "react";
import { Form, Select } from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";




export default function CompanyTypeSelector(props: any)
{
	const [selected, setSelected] = useState("defaultValue" in props ? props.defaultValue : 1);

	return (
	<Form.Field>
		<input type="hidden" name={props.name} value={selected} />
		<Select
			{...props}

			onChange = { (e, {value}) => setSelected(value as number) }

			options={[
				{value: 1, text: I18N.MEMBER},
				{value: 2, text: I18N.PARTNER},
			]}
		/>
	</Form.Field>);
}
