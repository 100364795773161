import moment from "moment";
import * as React from "react";
import { DropdownItemProps, Form } from "semantic-ui-react";
import I18N from "../../common/i18n/I18N";
import DecodeYW from "../capacity-calendar/DecodeYW";
import EncodeYW from "../capacity-calendar/EncodeYW";




interface IProps extends Partial<React.InputHTMLAttributes<HTMLInputElement>>
{
	onlyFuture?: boolean;
}



interface IState
{
	year: number;
	week: number;
}




export default class YearWeekSpinner extends React.Component<IProps, IState>
{
	state =
	{
		...this.getDefaultYearWeek(),
	};



	getDefaultYearWeek(): {year: number, week: number}
	{
		const {defaultValue} = this.props;

		const yw = defaultValue === undefined ? undefined : parseInt(this.props.defaultValue.toString());

		if (yw !== undefined)
		{
			return DecodeYW(yw);
		}
		else
		{
			return {year: moment().year(), week: moment().isoWeek()};
		}
	}




	getWeekOptions(): DropdownItemProps[]
	{
		const weeks = moment().year(this.state.year).isoWeeksInYear();
		const current = EncodeYW(moment().year(), moment().isoWeek());

		const options: DropdownItemProps[] = [];

		for (let weekIndex = 0; weekIndex < weeks; weekIndex++)
		{
			const yw = EncodeYW(this.state.year, weekIndex + 1);

			if (!this.props.onlyFuture || yw >= current)
			{
				const m = moment().year(this.state.year).isoWeek(weekIndex + 1);

				const text = (weekIndex + 1).toString();

				const description = [
					m.weekday(0).format("Do MMM"),
					m.weekday(6).format("Do MMM"),
				].join("-");

				if (this.state.week === weekIndex + 1)
				{
					options.push({
						key: weekIndex,
						text: `${text} (${description})`,
						value: weekIndex + 1,
					} as DropdownItemProps);
				}
				else
				{
					options.push({
						key: weekIndex,
						text,
						value: weekIndex + 1,
						description,
					} as DropdownItemProps);
				}
			}
		}

		return options;
	}



	getValue()
	{
		return EncodeYW(this.state.year, this.state.week);
	}




	setYear(year: number)
	{
		if (this.props.onlyFuture)
		{
			const currentYear = moment().year();

			if (year < currentYear)
				year = currentYear;
		}

		this.setState({year});
	}




	render()
	{
		return (
		<Form.Group widths="equal">

			{
				this.props.name !== undefined &&
				<input type="hidden" name={this.props.name} value={this.getValue()}/>
			}


			<Form.Input
				fluid type="number"
				placeholder={I18N.YEAR}
				label={I18N.YEAR}
				value = {this.state.year}
				onChange = { (event) => this.setYear( parseInt(event.target.value) ) }
			/>

			<Form.Dropdown
				search selection fluid
				placeholder=""
				label={I18N.WEEK}
				options={this.getWeekOptions()}
				value = {this.state.week}
				onChange = {(event, data) => this.setState({week: parseInt(data.value as string)})}
			/>
		</Form.Group>);
	}
}
