import * as React from "react";
import { DropdownProps, Form, SelectProps } from "semantic-ui-react";




interface IState
{
	value: DropdownProps["value"];
}




export default class InputSelector extends React.Component<SelectProps, IState>
{
	state =
	{
		value: this.props.defaultValue,
	};




	render()
	{
		const selectProps = {...this.props};

		const onChange = this.props.onChange;

		delete selectProps.onChange;

		return (
			<Form.Field>
				<input
					type="hidden"
					name={this.props.name}
					value=
					{
						(this.state.value === undefined || this.state.value === null)
						?
						""
						:
						this.state.value.toString()
					}
				/>


				<Form.Select
					{...selectProps}

					onChange = {
						(event, data) =>
						{
							this.setState({value: data.value});

							if (onChange !== undefined)
								onChange(event, data);
						}
					}
				/>
			</Form.Field>
		);
	}
}
