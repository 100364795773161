import linkifyHtml from "linkifyjs/html";
import * as React from "react";


interface IProps
{
	as?: any;
	content?: string;
}


export default class PreWrapText extends React.Component<IProps>
{
	render()
	{
		const Tag: any = this.props.as === undefined ? "p" : this.props.as;

		if (!this.props.content)
		{
			return (
			<Tag style = {{"whiteSpace": "pre-wrap"}}>
				{this.props.children}
			</Tag>);
		}
		else
		{
			return (
			<Tag
				style = {{"whiteSpace": "pre-wrap"}}
				dangerouslySetInnerHTML= {{ __html: linkifyHtml(this.props.content) }}
			/>);
		}

	}
}
