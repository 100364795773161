import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, Form} from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import IModelLocation from "../../model/IModelLocation";
import IModelYiiFieldError from "../../model/IModelYiiFieldError";
import FieldForModel from "../for-model/FieldForModel";
import "./Style.scss";




interface IProps
{
	model: Partial<IModelLocation>;
	onSuccess: () => void;
}




interface IState
{
	phase: PhaseFlags;
	errors: IModelYiiFieldError[];
}




export default class SingleLocationEditor extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		phase: PhaseFlags.NONE,
		errors: [],
	};




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}



	formOnSubmit(formEvent: React.FormEvent<HTMLFormElement>)
	{
		const formData = new FormData(formEvent.target as HTMLFormElement);

		PrettyPrintFormData(formData);


		const query: any = {"r": "active/location/create"};

		if (this.props.model.id !== undefined)
		{
			query.r = "active/location/update";
			query.id = this.props.model.id;
		}

		console.log(query);

		this.setState({phase: PhaseFlags.SAVING});

		new Fetcher<IModelLocation>(query)
		.post(formData)
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			console.log(data);
			this.props.onSuccess();
		})
		.onYiiFieldError((errors) =>
		{
			this.setState({errors, phase: PhaseFlags.NONE});
		})
		.run();
	}




	render()
	{
		const isCreating = (this.props.model.id === undefined);
		const disabled = {disabled: this.state.phase === PhaseFlags.SAVING};

		return (
		<Form onSubmit={this.formOnSubmit.bind(this)} className="wrapper">

			{
				!!this.props.model.parent_id
				&&
				<input
					type = "hidden"
					name = "parent_id"
					value = {this.props.model.parent_id}
				/>
			}

			<FieldForModel<IModelLocation> {...disabled}
				model = {this.props.model as any}
				errors = {this.state.errors}
				required
				field = "title"
				label = {I18N.TITLE}
				control = {<Form.Input size="huge" placeholder = {I18N.REGION} />}
			/>

			<FieldForModel<IModelLocation> {...disabled}
				model = {this.props.model as any}
				errors = {this.state.errors}
				field = "description"
				label = {I18N.DESCRIPTION}
				control =
				{<TextareaAutosize
					minRows = {2}
					rows = {2}
					placeholder = {I18N.DESCRIPTION}
				/>}
			/>

			<Button primary type="submit" disabled={disabled.disabled} loading={disabled.disabled}>
				{isCreating ? I18N.ADD_NEW : I18N.SAVE}
			</Button>

		</Form>);
	}
}
