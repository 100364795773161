import _ from "lodash";
import * as React from "react";
import { Divider, Dropdown, Header, Label, Loader, Menu, Popup, Segment, Tab } from "semantic-ui-react";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import PrettyPrintFormData from "../../common/PrettyPrintFormData";
import IModelCompanyService from "../../model/IModelCompanyService";
import IModelService from "../../model/IModelService";



interface IProps
{
	companyId: number;
	models: IModelCompanyService[];
	onChange: () => void;
}




interface IState
{
	phase: PhaseFlags;
	models: IModelService[];
}




export default class ServicesList extends React.Component<IProps, IState>
{
	fetchers = Fetcher.CreateList();


	state =
	{
		phase: PhaseFlags.NONE,
		models: [] as IModelService[],
	};




	componentDidMount()
	{
		this.fetchTree();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchTree()
	{
		this.setState({phase: PhaseFlags.FETCHING, models: []});

		new Fetcher<IModelService[]>(
		{
			"r": "active/service/index",
			"filter[parent_id]": null,
			"expand": "services.services",
		})
		.delay(500)
		.get()
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			// sort services
			data = data.sort((a,b) => a.name.localeCompare(b.name));
			this.setState({models: data, phase: PhaseFlags.NONE});
		})
		.run();
	}




	render()
	{
		return (
		<Segment.Group>
			<Segment secondary>
				<Header as = "h5">{I18N.SERVICES}</Header>
			</Segment>

			<Segment secondary textAlign="left" attached>
				{
					(this.props.models === undefined || this.props.models.length === 0)
					?
					<Header disabled textAlign="center">{I18N.SERVICES}</Header>
					:
					<div>
					{ this.props.models.sort((a,b)=> a.service.name.localeCompare(b.service.name)) .map((serviceCompany) => (
						<div
							key = {serviceCompany.id}
							style = {{margin: "3px", display: "inline-block"}}
						>
						<Label
							image
							onClick = {() => this.deleteService(serviceCompany.id)}
							color = "blue"
							size = "large"
						>
							{serviceCompany.service.name}
							<Popup
								trigger={<i className="delete icon"></i>}
								content={I18N.DELETE}
								position="top center"
								size="small"
								inverted
								mouseEnterDelay={500}
								mouseLeaveDelay={0}
							/>
						</Label>
						</div>
					)) }
					</div>
				}
				<Divider/>

				{
					this.state.phase !== PhaseFlags.NONE
					?
					<Loader active inline="centered"/>
					:
					this.renderAddNewUi()
				}
			</Segment>
		</Segment.Group>);
	}




	renderAddNewUi()
	{
		const servicesA = this.state.models;

		return (
		<Tab
			menu = {{ pointing: true }}
			panes =
			{
				servicesA.map((serviceA) =>
				{
					return {
						menuItem: serviceA.name,
						render: () => <React.Fragment key = {serviceA.id}>
							{
								serviceA.services.sort((a,b) => a.name.localeCompare(b.name)).map((serviceB) => (
								<div
									key = {serviceB.id}
									style = {{margin: "2px", display: "inline-flex", minWidth: "150px"}}
								>
								<Menu fluid>
								<Dropdown
									text = {serviceB.name}
									scrolling
									item
									fluid
									options =
									{
										serviceB.services.sort((a,b) => a.name.localeCompare(b.name)).map((serviceC) =>
										{
											const index = _.findIndex(this.props.models, (model) => model.service.id === serviceC.id);

											const option = {
												key: serviceC.id,
												text: serviceC.name,
												disabled: index !== -1,
											} as any;

											if (index === -1)
											{
												option.onClick = () => this.addService(serviceC.id);
											}

											return option;
										})
									}
								/>
								</Menu>
								</div>))
							}
						</React.Fragment>,
					};
				})
			}
		/>);
	}




	addService(serviceId: number)
	{
		const formData = new FormData();

		formData.set("company_id", this.props.companyId.toString());
		formData.set("service_id", serviceId.toString());

		PrettyPrintFormData(formData);

		const query: any = {"r": "node/company-edit/add-service"};

		this.setState({phase: PhaseFlags.SAVING});

		new Fetcher<IModelCompanyService>(query)
		.post(formData)
		.delay(500)
		.appendTo(this.fetchers)
		.onSuccess(({data}) =>
		{
			this.setState({phase: PhaseFlags.NONE});
			this.props.onChange();
		})
		.run();
	}




	deleteService(id: number)
	{
		if (window.confirm(I18N.ARE_YOU_SURE + "?"))
		{
			const formData = new FormData();

			formData.set("id", id.toString());

			PrettyPrintFormData(formData);

			const query: any = {"r": "node/company-edit/delete-service"};

			this.setState({phase: PhaseFlags.SAVING});

			new Fetcher(query)
			.post(formData)
			.delay(500)
			.appendTo(this.fetchers)
			.onSuccess(() =>
			{
				this.setState({phase: PhaseFlags.NONE});
				this.props.onChange();
			})
			.run();
		}
	}
}
