import * as React from "react";
import { Form, Select } from "semantic-ui-react";
import User from "../../common/User";




export default class RoleSelector extends React.Component<any>
{
	state =
	{
		roles: [{text: "User",  value: 0}, {text: "Admin", value: 9}],
		value: "",
	};



	componentDidMount()
	{
		if (User.IsGod)
			this.setState({roles: [{text: "User",  value: 0}, {text: "Admin", value: 9}, {text: "System Admin", value: 10}]});

		if ("defaultValue" in this.props)
			this.setState({value: this.props.defaultValue});
	}




	render()
	{
		return (
			<Form.Field>
				<input type="hidden" name={this.props.name} value={this.state.value} />
				<Select
					{...this.props}

					onChange = { (e, data) => this.setState({value: data.value}) }

					options={ this.state.roles.map(
						(role, index) =>
						{
							return {key: index, ...role};
						},
					)}
				/>
			</Form.Field>
		);
	}
}
