import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { Header, Icon, Label, List, Popup, Segment, Tab} from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import Fetcher from "../../common/Fetcher";
import I18N from "../../common/i18n/I18N";
import PhaseFlags from "../../common/PhaseFlags";
import IModelCapacity from "../../model/IModelCapacity";
import IModelCapacityCompany from "../../model/IModelCapacityCompany";
import DecodeYW from "../capacity-calendar/DecodeYW";
import Emsp from "../ui-element/Emsp";
import PreWrapText from "../ui-element/PreWrapText";
import WaitingUiElement from "../ui-element/WaitingUiElement";




interface IProps
{
	type: 0|1;
	pane?: boolean;
	order: number;
}




interface IState
{
	models: IModelCapacity[];
	phase: PhaseFlags;
}




export default class OutCapacities extends React.Component<any, IState>
{
	fetchers = Fetcher.CreateList();




	state =
	{
		models: [] as IModelCapacity[],
		phase: PhaseFlags.NONE,
	};




	componentDidMount()
	{
		this.fetchModels();
	}




	componentWillUnmount()
	{
		Fetcher.CancelAll(this.fetchers);
	}




	fetchModels()
	{
		this.setState({phase: PhaseFlags.FETCHING});

		new Fetcher<IModelCapacity[]>
		(
			{
				"r": "node/capacity-crud/out-capacities",
				"type": this.props.type,
				"order": this.props.order,
			},
		)
		.get()
		.delay(500)
		.writeToCacheOnSuccess()
		.readFromCacheIfYoungerThan(50000)
		.appendTo(this.fetchers)
		.onSuccess( ({data}) => this.setState({models: data, phase: PhaseFlags.NONE}) )
		.onError( () => this.setState({phase: PhaseFlags.NONE}) )
		.run();
	}




	render()
	{
		const Wrapper = this.props.pane ? Tab.Pane : Segment;

		return (
		<Wrapper>
			{
				this.state.phase === PhaseFlags.NONE
				?
				this.renderModels(this.state.models)
				:
				WaitingUiElement.Tree()
			}
		</Wrapper>);
	}



	renderModels(models: IModelCapacity[])
	{
		if (models.length > 0)
		{
			return models.map((model) => this.renderModel(model));
		}
		else
		{
			return <p>{I18N.THERE_IS_NO_REQUEST_IN_THIS_CATEGORY}.</p>;
		}
	}




	renderModel(model: IModelCapacity)
	{
		type T = IModelCapacityCompany[];

		const requestsByType =
		[
			_.filter(model.capacityCompanies as T, (req) => req.status === 0),
			_.filter(model.capacityCompanies as T, (req) => req.status === 1),
			_.filter(model.capacityCompanies as T, (req) => req.status === 2),
		];


		return (
		<Segment key={model.id} vertical>

			<Header as="h3">
				{model.service.name}

				<div style={{float: "right"}}>
					{this.renderStatusGroupNumber("grey", requestsByType[0].length, I18N.WAITING_FOR_RESPONSE)}
					{this.renderStatusGroupNumber("green", requestsByType[1].length, I18N.APPROVED)}
					{this.renderStatusGroupNumber("orange", requestsByType[2].length, I18N.REJECTED)}
				</div>

				<Header.Subheader>
					{
						!!model.location &&
						<>
							<Icon name="map marker alternate"/>
							{model.location.title}
							<Emsp/>
						</>
					}

					<Icon name="clock" />
					{ this.renderTimeSpan(model.start_year_week, model.end_year_week) }
					<Emsp/>

					{
						model.user &&
						<>
							<Icon name="user"/>
							{model.user.nickname || model.user.email}
							<Emsp/>
						</>
					}
				</Header.Subheader>

  			</Header>

			{
				model.private_comment &&
				<PreWrapText content = {`<b>${I18N.PRIVATE_COMMENT}: </b> ${model.private_comment}`} />
			}

			{
				model.public_comment &&
				<PreWrapText content = {`<b>${I18N.PUBLIC_COMMENT}: </b> ${model.public_comment}`} />
			}

			{
				requestsByType[1].length > 0
				&&
				<Segment.Group>
					<Segment raised secondary>
						<Label color="green" ribbon> {I18N.APPROVED} </Label>

						<List divided relaxed>
						{
							requestsByType[1].map((req) =>
							{
								const names = [];
								let id = req.company.id;

								if (req.company.parent)
								{
									names.push(req.company.parent.name);
									id = req.company.parent.id;
								}

								names.push(req.company.name);

								return (
								<List.Item key={req.id}>
									<List.Content>
										<List.Header as = "a" href = {"#company_" + id}>
											{names.join(" / ")}
										</List.Header>
										{ req.company.contact }
									</List.Content>
								</List.Item>
								);
							})
						}
						</List>
					</Segment>
				</Segment.Group>
			}
		</Segment>);
	}




	renderTimeSpan(start_year_week: number, end_year_week: number)
	{
		const arr = [];

		const format = "Do MMM YYYY [(kw] W [)]";

		{
			const {year, week} = DecodeYW(start_year_week);
			arr.push(moment().year(year).isoWeek(week).weekday(0).format(format));
		}
		{
			const {year, week} = DecodeYW(end_year_week);
			arr.push(moment().year(year).isoWeek(week).weekday(6).format(format));
		}

		return arr.join(" - ");
	}




	renderStatusGroupNumber(color: SemanticCOLORS, content: any, popup: string)
	{
		return (
		<Popup
			trigger={<Label color={color} circular content={content} />}
			content={popup}
			inverted
		/>);
	}
}
