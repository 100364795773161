import * as React from "react";
import { Button, Icon, Placeholder } from "semantic-ui-react";



export default class FormViewUi
{
	static BackButton(label: string, id: string)
	{
		return (
		<Button type="reset"
			onClick={() => window.location.hash = id}
		>
			<Icon name="arrow alternate circle left"/> {label}
		</Button>);
	}


	static Waiting()
	{
		return (
		<Placeholder fluid>
			<Placeholder.Header>
				<Placeholder.Line/>
				<Placeholder.Line/>
			</Placeholder.Header>

			<Placeholder.Paragraph>
				<Placeholder.Line/>
				<Placeholder.Line/>
			</Placeholder.Paragraph>

			<Placeholder.Paragraph>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
			</Placeholder.Paragraph>

			<Placeholder.Paragraph>
				<Placeholder.Line/>
				<Placeholder.Line/>
			</Placeholder.Paragraph>

			<Placeholder.Paragraph>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
			</Placeholder.Paragraph>

			<Placeholder.Paragraph>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
				<Placeholder.Line/>
			</Placeholder.Paragraph>

			<Placeholder.Header>
				<Placeholder.Line length="very short"/>
			</Placeholder.Header>
		</Placeholder>);
	}
}
