import * as React from "react";
import { Placeholder } from "semantic-ui-react";

export default class WaitingUiElement
{
	static Tree()
	{
		return (
		<Placeholder fluid>
			<Placeholder.Header>
				<Placeholder.Line length = "very short"/>
			</Placeholder.Header>

			<Placeholder.Paragraph>
				<Placeholder.Line length = "short"	/>
				<Placeholder.Line length = "short"	/>
				<Placeholder.Line length = "medium"	/>
				<Placeholder.Line length = "long"	/>
				<Placeholder.Line length = "long"	/>
				<Placeholder.Line length = "full"	/>
				<Placeholder.Line length = "long"	/>
				<Placeholder.Line length = "full"	/>
				<Placeholder.Line length = "full"	/>
				<Placeholder.Line length = "medium"	/>
			</Placeholder.Paragraph>

			<Placeholder.Header>
				<Placeholder.Line length = "very short"/>
			</Placeholder.Header>

			<Placeholder.Header>
				<Placeholder.Line length = "very short"/>
			</Placeholder.Header>

			<Placeholder.Paragraph>
				<Placeholder.Line length = "short"	/>
				<Placeholder.Line length = "medium"	/>
				<Placeholder.Line length = "long"	/>
				<Placeholder.Line length = "full"	/>
				<Placeholder.Line length = "long"	/>
				<Placeholder.Line length = "short"	/>
				<Placeholder.Line length = "medium"	/>
			</Placeholder.Paragraph>
		</Placeholder>);
	}
}
